@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:700&display=swap');

.btnGeneralStyle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700 !important;
  height: 40px !important;
  font-size: 13px !important;
  width: 140px;
}

.cursorDefaultClass {
  cursor: default;
}
