.moreImagesContainer {
    position: absolute;
    height: 250px;
    width: 100%;
    line-height: 250px;
    font-size: 64px;
    color: black;
    background-color: rgba(255, 255, 255, 0.5);
    text-align: center;
    cursor: pointer;
}