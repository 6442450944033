.postInfoContainer {
  position: relative;
  margin: 16px 0;
  /* display: block; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* height: 32px;
  line-height: 32px; */
}

.postMoreInfo {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
}
