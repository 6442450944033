.stepperContainer {
    display: flex;
    justify-content: space-between;
}


/* style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}} */

.stepWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}


/* style={{backgroundColor: 'blue', borderRadius: '50%', width: '25px', height: '25px', color: 'white'}} */

.stepNumberWrapperGrey {
    font-size: 12px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: white;
    background-color: grey;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
    -moz-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
    box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.stepNumberWrapperBlue {
    font-size: 12px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: white;
    background-color: #167bff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
    -moz-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
    box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.dividerLineGrey {
    overflow: visible;
    height: 2px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 20px;
    width: 100%;
    background-color: #dbdbdb;
}

.dividerLineBlue {
    overflow: visible;
    height: 2px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 20px;
    width: 100%;
    background-color: #167bff;
}

.stepDescriptionGrey {
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
    margin-top: 8px;
    color: #7f7f7f;
}

.stepDescriptionBlue {
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
    margin-top: 8px;
    color: #167bff;
}