/* .label-label {
    float: left;
}

.form-control:focus {
    border-color: #167bff;
    box-shadow: none;
}

.custom-control-label {
    font-weight: 400;
}

.label-error {
    color: #cb2e25;
    float: left;
}

.span-error {
    color: #cb2e25;
    float: right;
}

.error {
    border-color: #cb2e25;
    color: #cb2e25;
}

.delete-span {
    color: red;
    font-size: 2rem;
}

.terapeutas li {
    float: left;
    text-decoration: none;
} */

.inputLabel {
  float: left;
}
