/* Font Combination
Montserrat: for titles and subtitles
Source Sans Pro: for paragraphs */

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap");

/* Montserrat Font Weigth
- regular 400
- bold 700
- extra-bold 800 --not imported
- black 900 --not imported*/

/* font-family: 'Montserrat', ; */

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,700&display=swap");

/* Montserrat Font Weigth
- regular 400
- regular italic 400i
- bold 700 */

/* font-family: 'Source Sans Pro', ; */

h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #6f6f6f;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #6f6f6f;
  font-size: 16px;
}

/* p, */
select,
button,
small,
span,
label,
th,
td,
li,
li a,
a {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-user-select: none !important;
}

button.close span {
  font-size: 24px;
  font-weight: 700;
  color: #7f7f7f;
  vertical-align: sub;
}

p {
  font-size: 16px;
  font-weight: 400;
  color: #7f7f7f;
}

li {
  font-size: 16px;
  font-weight: 400;
  color: #7f7f7f;
}

label {
  font-size: 13px;
  font-weight: 700;
  color: #7f7f7f;
}

/* span {
  font-size: 13px;
  font-weight: 400;
  color: #7f7f7f;
} */

th {
  font-size: 16px;
  font-weight: 700;
  color: #6f6f6f;
}

td {
  font-size: 16px;
  font-weight: 400;
  color: #7f7f7f;
  cursor: text;
  -webkit-user-select: all !important; /* Chrome 49+ */
  -moz-user-select: all !important; /* Firefox 43+ */
  -ms-user-select: all !important; /* No support yet */
  user-select: all !important;
}

li a {
  font-size: 16px;
  font-weight: 700;
  color: #7f7f7f !important;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

li.page-item.active span.page-link {
  font-size: 16px;
  font-weight: 700;
}

.table td,
.table th {
  vertical-align: middle;
}

.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  /* padding: 0px; */
  /* top: -16px !important; */
}
