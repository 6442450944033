.reaccionEmoji {
    cursor: pointer;
    margin-right: 4px;
    font-size: 16px;
    transition: all .05s ease-in-out;
}


/* .reaccionEmoji:hover {
    font-size: 24px;
    transform: scale(1.5);
} */

.reaccionesContainer {
    height: 32px;
    line-height: 32px;
}

.reaccionContainer {
    display: inline-block;
    height: 24px;
    line-height: 24px;
}