.customButton {
  border: none;
  background: white;
  margin: 0px 4px 0px 0px;
  padding: 0px;
}
.customButton:focus {
  outline: none;
}

.customSvg {
  height: 32px !important;
  width: 32px !important;
}

.customSvgDisapprove:hover {
  color: #cb2e25 !important;
}

.customSvgApprove:hover {
  color: #0079e1 !important;
}

.searchContainer {
  margin: 0px 0px 24px 0px;
}

.buttonEmptyState {
  display: inline-block;
}

.searchBoxCon {
  padding: 0px 0px 0px 0px;
}
