.container > div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
}

.container > div > div {
  flex-basis: 50%;
}

.container > div > div h3 {
  font-size: 17px;
}

.container > div > div p {
  font-size: 20px;
}
