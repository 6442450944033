.textConcept {
  /* padding-left: 0px; */
  padding-right: 0px;
  margin-bottom: 0px;
}

.textValue {
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0px;
}

.rowStyle {
  margin: 0px 0px 16px 0px;
}

.dataBox {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  padding: 0px 0px 0px 0px !important;
}
