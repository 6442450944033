.modalPropio {
  position: absolute;
  /* estaba comentado por las dudas*/
  top: 50%;
  left: 50%;
  padding: 10px;
  width: 200px;
  height: 400px;
  /* z-index: 9999999999; */
}

.modalHeaderContainer {
  display: flex;
  justify-content: space-between;
  height: 33.33%;
}

.modalContentContainer {
  height: 33.33%;
}

.modalButtonsContainer {
  display: flex;
  justify-content: flex-start;
  height: 33.33%;
}
