
.filesContainer {
    /* margin: 0px 0px 16px 8px; */
    display: flex;
    align-items: center;
}

.labelForFiles {
    margin-bottom: 0px;
}

.textFiles {
    color: #167bff;
    margin: 0px 0px 0px 8px;
    font-size: 14px;
}


@media only screen and (max-width: 768px) {
    .filesContainer {
        margin: 0px 0px 0px 8px;
    }
    .buttonsSameLine {
        padding-top: 16px;
    }
}
