.modalPropio {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 10px;
    width: 200px;
    height: 400px;
  }
  
  .modalHeaderContainer {
    display: flex;
    justify-content: space-between;
    height: 33.33%;
  }
  
  .modalContentContainer {
    height: 33.33%;
  }
  
  .modalButtonsContainer {
    display: flex;
    justify-content: flex-start;
    height: 33.33%;
  }
  
  .modalBody {
    max-height: 460px;
    height: 460px;
    overflow-y: auto;
    scroll-behavior: contain;
  }