/* .descripcion{
    margin:auto;
    justify-content: start;
} */
/* .standardDescription {
  text-align: left;
  justify-content: start;
  overflow: clip;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  height: 66%;
} */
.terapiaCard {
  height: 240px;
  border-radius: 6px;
  border-color: #efefef;
  -webkit-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  -moz-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.terapiaContainer {
  position: relative;
  float: left;
  display: flex;
  flex-direction: column;
}

.terapiaButtons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 87%;
}
/* .titleClass {
  text-align: left;
} */

/* styles for '...' */
.titleBlockWithText {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 2.4em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}

.descriptionBlockWithText {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 6em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}
/* create the ... */
.titleBlockWithText:before,
.descriptionBlockWithText:before {
  /* points in the end */
  content: '...';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}
/* hide ... if we have text, which is less than or equal to max lines */
.titleBlockWithText:after,
.descriptionBlockWithText:after {
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}

.customButton {
  border: none;
  background: white;
  margin: 0px 4px 0px 4px;
  padding: 0px;
}
.customButton:focus {
  outline: none;
}

.customSvg {
  height: 32px !important;
  width: 32px !important;
}

.customSvgDelete:hover {
  color: #cb2e25 !important;
}

.customSvgEdit:hover {
  color: #0079e1 !important;
}

.buttonsContainer {
  height: 46px;
}
