.link_therapy {
  text-decoration: none;
  color: white;
  display: block;
  padding: 5px 10px;
  text-align: center;
  border-radius: 5px;
}

.link_therapy:hover {
  opacity: 0.85;
  color: white;
}

.link_info {
  text-decoration: none;
  color: #7f7f7f;
}

.link_info:hover {
  text-decoration: underline;
  color: #0079e1;
}
