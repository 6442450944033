.tooltipContainer {
  padding: 8px 16px;
  height: 56px;
  background: black;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 35px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(222, 222, 222, 1);
  -moz-box-shadow: 0px 0px 8px 0px rgba(222, 222, 222, 1);
  box-shadow: 0px 0px 8px 0px rgba(222, 222, 222, 1);
  display: flex;
  align-items: center;
  z-index: 10;
}

.reaccionesButtonContainerSinReaccion {
  background-color: #fafafa;
  /* padding: 8px 16px; */
  border-radius: 35px;
  display: flex;
  height: 40px;
  min-width: fit-content;
}

.reaccionesButtonContainerSinReaccion:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}

.reaccionesButtonContainerConReaccion {
  background-color: #167bff;
  /* padding: 8px 16px; */
  border-radius: 35px;
  display: flex;
  height: 40px;
  min-width: fit-content;
}

.reaccionesButtonContainerConReaccion:hover {
  background-color: #3a8fff;
  cursor: pointer;
}

.reaccionesButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.reaccionesButtonContainerConReaccion div p,
.reaccionesButtonContainerSinReaccion div p {
  margin: 0px 0px 0px 8px;
}

.reaccionesButtonContainerConReaccion div p {
  color: white;
}

.emojiButton {
  font-size: 14px;
  margin: '0 8px 0 0';
}

.noselect {
  -webkit-touch-callout: none!important; /* iOS Safari */
    -webkit-user-select: none!important; /* Safari */
     -khtml-user-select: none!important; /* Konqueror HTML */
       -moz-user-select: none!important; /* Old versions of Firefox */
        -ms-user-select: none!important; /* Internet Explorer/Edge */
            user-select: none!important; /* Non-prefixed version, currently, supported by Chrome, Edge, Opera and Firefox */
}