.novo {
    color: #595959;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
}

.labs {
    color: #ff6b6b;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.feedbackTextarea {
    outline: none;
    border: solid 2px #efefef;
    /* width: 100%; */
    padding: 1px;
}

.novolabs {
    width: 150px;
    height: 40px;
}

.feedbackBox {
    display: flex;
    justify-content: center;
    margin-top: -16px;
    margin-bottom: 64px;
}

.closetag {
    align-self: center;
}

.feedbackOptions img {
    height: 50px;
    width: 50px;
}

.feedbackOptions button:hover {
    cursor: pointer;
    height: 85px;
    width: 85px;
    margin-left: -5px;
}

.feedbackOptions button {
    border-radius: 16px;
    width: 80px;
    height: 80px;
    background-color: #FAFAFA;
    border: 1px transparent;
    -webkit-box-shadow: 4px 12px 12px -8px #777;
    -moz-box-shadow: 4px 12px 12px -8px #777;
    box-shadow: 4px 12px 12px -8px #777;
    transition: ease 0.1s;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 120px;
}

.buttonsContainer button span {
    margin: auto;
}


/*Hover message*/

.tooltip {
    background-color: #ff6b6b !important;
}