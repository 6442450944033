.notificacionContainerLeida {
    max-width: 50px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
}

.notificationContainer {
    display: flex;
    align-items: flex-start;
    margin: 16px 0;
    padding: 0 8px;
    border-radius: 4px;
    text-align: left;
}

.notificationContainer:hover {
    background-color: #FAFAFA;
    cursor: pointer;
}

#notificacionMensaje {
    margin-left: 8px;
    max-width: 25px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

@media (min-width: 768) {
    .notificationContainer {
        max-width: 400px;
        width: 400px;
    }
}