@media screen and (max-width: 650px) {
  .stepperWeb {
    display: none;
  }
}

@media screen and (min-width: 651px) {
  .stepperMobile {
    display: none;
  }
}
