.spinnerContainer {
    border: 1px;
    border-bottom-style: solid;
    border-color: #efefef;
}

.secondRowClass {
    margin: 0px 0px 0px 0;
    padding: 8px 0px 8px 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.avatarColumn {
    display: flex;
    flex-direction: column;
    /* padding: 0px; */
    justify-content: flex-end;
    align-items: center;
}

.commentContentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding-left: 0px; */
}

.textAreaContainer {
    max-height: 364px;
    width: 100%;
    overflow: auto;
    word-wrap: break-word;
    overscroll-behavior: contain;
}

.textAreaContainerMobile {
    width: 100%;
    padding: 4px 8px 0 0;
}

.textAreaClass {
    text-align: left;
    /* height: 40px;
    max-height: 240px; */
    /* max-height: min-content; */
    width: 100%;
}

/* .textAreaClass::placeholder {
    text-align: left;
    vertical-align: top !important;
} */

.colButtonClass {
    display: flex;
    flex-direction: column;
    padding: 0px;
    justify-content: flex-end;
    align-items: center;
}

.loadMoreContainer {
    padding: 0 16px 0 65px;
    text-align: left;
    /* display: flex;
    justify-content: space-between; */
}

.linkLoadMore {
    color: #007bff !important;
    cursor: pointer;
    font-size: 12px;
    line-height: 12px;
}

.linkLoadMore:hover {
    text-decoration: underline !important;
}

@media only screen and (max-width: 890px) {
    .avatarColumn {
        padding: 0px 0px 0px 15px !important;
    }
    .textAreaContainer {
        padding: 4px 8px 0px 16px;
    }
}

@media only screen and (max-width: 590px) {
    .avatarColumn {
        padding: 0px 0px 0px 15px !important;
    }
    .textAreaContainer {
        padding: 4px 8px 0px 20px;
    }
}

@media only screen and (max-width: 490px) {
    .avatarColumn {
        padding: 0px 0px 0px 15px !important;
    }
    .textAreaContainer {
        padding: 4px 8px 0px 24px;
    }
}

@media only screen and (max-width: 420px) {
    .avatarColumn {
        padding: 0px 0px 0px 15px !important;
    }
    .textAreaContainer {
        padding: 4px 8px 0px 32px;
    }
}

@media only screen and (max-width: 767px) {
    .loadMoreContainer {
        padding: 0 0px 0 32px;
    }
}

@media (min-width: 768px) {
    .linkLoadMore {
        font-size: 14px;
    }
}