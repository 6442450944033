.buscadorContainer {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  border: 1px solid #efefef;
  border-radius: 6px;
  background-color: #fff;
  /* -webkit-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  -moz-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1); */
}

.buscador {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 6px;
  padding: 4px 8px 4px 12px;
  opacity: 1;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
