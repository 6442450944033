@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

.inputLabel {
  float: left;
}

.inputError {
  border-color: #cb2e25 !important;
  color: #cb2e25 !important;
}

.labelError {
  color: #cb2e25 !important;
}
