.badgeTerapia {
  /* margin: 0 0 0 8px; */
  padding: 4px 8px 4px 8px;
}

.spanSecondary {
  background-color: #efefef !important;
  color: #454545 !important;
  margin: 0px 4px 0px 4px;
}
