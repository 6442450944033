.reaccionEmoji {
    cursor: pointer;
    font-size: 24px;
    transition: all .05s ease-in-out;
    margin: 0 -4px;
}

.marginHeight {
    min-height: 56px;
    margin: 0 0 32px 0;
}