.customButtonLeft {
  border: none;
  background: white;
  margin: 0px 0px 0px 8px;
  padding: 0px;
}
.customButtonLeft:focus {
  outline: none;
}

.customButtonRight {
  border: none;
  background: white;
  margin: 0px 0px 0px 16px;
  padding: 0px;
}
.customButtonRight:focus {
  outline: none;
}

.customSvg {
  height: 32px !important;
  width: 32px !important;
}

.customSvgDelete:hover {
  color: #cb2e25 !important;
}

.customSvgEdit:hover {
  color: #0079e1 !important;
}

.searchContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 24px 0px;
}

.buttonEmptyState {
  display: inline-block;
}

.searchBoxCon {
  padding: 0px 0px 0px 0px;
}

.btnCon {
  padding: 0px 0px 0px 0px;
  height: 40px;
}

.containerActions {
  display: flex;
  flex-flow: nowrap;
}

.containerActionsIcons {
  display: flex;
  flex-flow: nowrap;
  margin-left: 16px;
}

.containerActionsIcons a {
  width: 32px;
  height: 40px;
}

@media screen and (max-width: 768px) {
  .btnCon {
    margin-top: 16px;
  }
}
