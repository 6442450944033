#quillEditorContainer {
  height: 90%;
}

#quillEditorContainer .quill .ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #ccc;
}

.ql-container.ql-snow {
  border: none;
}

.ql-toolbar.ql-snow {
  border: none;
  display: flex;
  justify-content: space-evenly;
}

#quillEditorContainer .quill .ql-container.ql-snow {
  height: 90%;
}

#quillEditorContainer .quill .ql-toolbar.ql-snow .ql-formats {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

#quillEditorContainer .quill .ql-container.ql-snow .ql-editor {
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
}

@media (min-width: 768px) {
  #quillEditorContainer .quill .ql-container.ql-snow .ql-editor {
    min-height: 47vh;
    max-height: 47vh;
    height: 47vh;
  }

  #quillEditorContainer .quill .ql-container.ql-snow {
    border: none;
    /* min-height: 364px; */
  }
}
