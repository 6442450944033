#notificationContainer {
    position: relative;
}

#notificationBadge {
    position: absolute;
    top: 0px;
    right: 25%;
}

#notificationItem {
    cursor: pointer;
}

#notificationItem:hover {
    color: #212529AA;
}

.notifDropdown {
    /* position: fixed;
    top: 56px!important;
    right: 0;
    left: 0; */
    max-height: 90vh;
    height: 90vh;
    overflow-y: auto;
    scrollbar-width: none;
    overscroll-behavior: contain;
    /* overflow-x: hidden; */
    width: 100vw;
}

@media (min-width: 992px) {
    .notifDropdown {
        max-height: 80vh;
        height: 80vh;
        overflow-y: auto;
        width: 30vw;
        margin: 0;
        }
        
}