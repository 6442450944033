@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:700&display=swap');
.btnGeneralStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700 !important;
  border: solid 1px;
  color: #7f7f7f !important;
  /* background-color: #fafafa; */
  /* border-color: #dfdfdf; */
  border-color: #dfdfdf;
  background-color: #ffffff;
  border-radius: 35px;
  height: 40px !important;
  font-size: 14px !important;
}

a.btnGeneralStyle:hover {
  border-color: #25d366;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(230, 230, 230, 1);
  -moz-box-shadow: 0px 0px 10px 1px rgba(230, 230, 230, 1);
  box-shadow: 0px 0px 10px 1px rgba(230, 230, 230, 1);
}
