@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:700&display=swap');

input[type='file'] {
  display: none;
}
.customFileUpload {
  border: 1px solid #ccc;
  display: inline-block;
}

.btnBootstrap {
  border: 2px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 13px;
  height: 48px;
  user-select: none;
  display: flex;
  align-items: center;
  place-content: center;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  margin: 0px;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #007bff;
  border-color: #007bff;
  width: 100%;
  cursor: pointer;
}
