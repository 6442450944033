.box {
  margin: 32px 0 32px 0;
  background-color: #ffffff;
  border: 1px;
  border-style: solid;
  border-color: #efefef;
  border-radius: 6px;
  -webkit-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  -moz-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.textoClass {
  margin-bottom: 0px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.secondRowClass {
  margin: 0px 0px 0px 0;
  padding: 16px 0px 8px 0px;
}

.inputClass {
  text-align: left;
  vertical-align: top !important;
  height: 100px;
  max-height: min-content;
  width: 100%;
}

.inputClass.break {
  word-wrap: break-word;
  word-break: break-all;
}

.inputClass::placeholder {
  text-align: left;
  vertical-align: top !important;
}

textarea {
  resize: none;
  border: 0px;
}

textarea:focus,
input:focus,
button:focus,
select:focus {
  outline: none;
}

.selectClass {
  border-radius: 35px;
  height: 40px;
}

.containerPersonalData {
  margin-bottom: 16px;
}

.containerPersonalData p {
  font-weight: bold;
  margin-bottom: 0px;
}

.containerPersonalData small {
  font-size: 12px;
}
/* 
textarea:focus,
input:focus,
button:focus,
select:focus {
    outline: none;
} */

.colButtonClass {
  padding: 0px;
}

.postActions {
  margin: 16px 0;
}

.customButton {
  border: none;
  background: white;
  margin: 0px 4px 0px 4px;
  padding: 0px;
  align-self: flex-start;
}

.customButton:focus {
  outline: none;
}

.viewMoreShadow {
  background-image: linear-gradient(to bottom, transparent 56px, white);
}

.customSvgDelete:hover {
  color: #cb2e25 !important;
}

.avatarColumn {
  padding: 0px 0px 0px 15px !important;
}

.inputContainerClass {
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
}

.nombrePacienteClass {
  display: inline-block;
  text-align: left;
  margin-bottom: 0px;
}

.smallAntiguedad {
  display: block;
  text-align: start;
}

.firstRowClass {
  background-color: #ffffff;
  margin: 16px 0px 0 0px;
  padding-left: 16px;
  padding-right: 8px;
  border-width: 1px;
  border: 1px;
  border-bottom-style: solid;
  border-color: #efefef;
}

@media (min-width: 768px) {
  .avatarColumn {
    padding: 0px 0px 0px 15px !important;
    margin-top: 6px;
  }
  .inputContainerClass {
    text-align: left;
    margin-bottom: 16px;
    padding: 0px 8px 0px 16px;
  }
  .customButton {
    align-self: unset;
  }
  .firstRowClass {
    margin: 16px 0px 0px 0px;
    padding-right: 0px;
  }
  .nombrePacienteClass {
    display: inline !important;
  }
  .smallAntiguedad {
    margin-left: 8px;
    display: inline-block;
    text-align: start;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
