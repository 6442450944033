.avatarContainerXS {
    height: 24px;
    width: 24px;
}

.avatarContainerS {
    height: 32px;
    width: 32px;
}

.avatarContainerM {
    height: 40px;
    width: 40px;
}

.avatarContainerL {
    height: 48px;
    width: 48px;
}

.avatarContainerXL {
    height: 80px;
    width: 80px;
}

.avatar {
    object-fit: cover;
    object-position: center center;
    border-radius: 50%;
}


/* .avatar {
    border-radius: 50%;
    background-size: cover;
} */