.inputContainerClass {
    text-align: left;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.containerPersonalData {
    margin-bottom: 8px;
}

.containerPersonalData p {
    font-weight: bold;
    margin-bottom: 0px;
}

.textParagraph {
    margin-bottom: 0px;
    font-size: 14px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    /* padding-right: 28px; */
}

.colAvatar {
    /* margin-top: 6px; */
    padding: 0px 15px 0px 0px;
    max-width: max-content;
}

.customButton {
    border: none;
    background: white;
    padding: 4px 0px 0px 0px;
    align-self: flex-start;
}

.customButton:focus {
    outline: none;
}

.customSvgDelete:hover {
    color: #cb2e25 !important;
}

.firstRowClass {
    background-color: #FDFFFD;
    padding: 16px 16px 16px 32px;
    margin: 0px 0px 0px 0px;
    border-width: 1px;
    border: 1px;
    border-bottom-style: solid;
    border-color: #efefef;
    flex-wrap: nowrap !important;
}

.nombrePacienteClass {
    display: inline-block;
    text-align: left;
    margin-bottom: 0px;
}

.smallAntiguedad {
    display: block;
    text-align: start;
}

@media (min-width: 768px) {
    .firstRowClass {
        background-color: #ffffff;
        padding: 16px 8px 16px 65px;
        margin: 0px 0px 0px 0px;
        border-width: 1px;
        border: 1px;
        border-bottom-style: solid;
        border-color: #efefef;
        flex-wrap: nowrap !important;
    }
    .nombrePacienteClass {
        display: inline !important;
        text-align: center;
    }
    .smallAntiguedad {
        margin-left: 8px;
        display: inline-block;
        text-align: start;
    }
    .customButton {
        border: none;
        background: white;
        padding: 0px;
    }
    .textParagraph {
        margin-bottom: 0px;
        font-size: 14px;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        padding-right: 28px;
    }
}


/* @media only screen and (max-width: 890px) {
  .inputContainerClass {
    padding: 0px 0px 0px 16px !important;
  }
}

@media only screen and (max-width: 590px) {
  .inputContainerClass {
    padding: 0px 0px 0px 20px !important;
  }
}

@media only screen and (max-width: 490px) {
  .inputContainerClass {
    padding: 0px 0px 0px 24px !important;
  }
}

@media only screen and (max-width: 420px) {
  .inputContainerClass {
    padding: 0px 0px 0px 32px !important;
  }
} */


/* @media only screen and (max-width: 717px) {
    .colAvatar {
        padding: 0px 0px 0px 0px !important;
    }
    .inputContainerClass {
        padding: 0px 0px 0px 15px !important;
    }
} */