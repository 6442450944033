.inputLabel {
  float: left;
}

.inputError {
  border-color: #cb2e25 !important;
  color: #cb2e25 !important;
}

.labelError {
  color: #cb2e25 !important;
}

.inputSelect {
  -webkit-user-select: text !important; /* Chrome, Opera, Safari */
  -moz-user-select: text !important; /* Firefox 2+ */
  -ms-user-select: text !important; /* IE 10+ */
  user-select: text !important; /* Standard syntax */

}