@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

.messageClass {
  font-size: 13px;
  color: #7f7f7f;
  text-align: left;
}

.spanError {
  color: #cb2e25;
}
