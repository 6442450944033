.selectedList {
  list-style: none;
  padding: 0px;
  text-align: left;
}

.selectedItem {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.deleteSelectedItem {
  cursor: pointer;
}

.containerButton {
  margin-bottom: 24px;
}

.btnGeneralStyle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700 !important;
  font-size: 13px !important;
  height: 40px;
}

.terapeutaName {
  margin: 0px 0px 0px 16px;
}
