.custom-control-label {
  font-weight: 400;
}

.refText,
.refText a {
  text-align: left;
}

.refText a {
  color: #167bff;
}

.buttonPosition {
  padding: 0 0 0px 0px !important;
}

.noHayAvatar {
  padding-right: 0px;
  padding-left: 24px;
  display: flex;
  flex-direction: row;
  align-self: center;
}

.avatarContainer {
  padding-right: 0px;
  padding-left: 24px;
  display: flex;
  flex-direction: row;
}

.avatar {
  margin-right: 16px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 1199px) {
  .noHayAvatar {
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 16px;
  }
}

@media (max-width: 1199px) {
  .avatarContainer {
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 16px;
  }
}

@media (max-width: 1199px) {
  .containerAvatarMax {
    flex-direction: column;
  }
}

/* Step 1 - AM Paciente */
@media (max-width: 1199px) {
  .listFilePreview {
    margin-top: 16px;
  }
}
