.reaccionEmoji {
  cursor: pointer;
  /* margin-right: 4px; */
  font-size: 32px;
  -webkit-transition: font-size 0.2s ease;
  -moz-transition: font-size 0.2s ease;
  -o-transition: font-size 0.2s ease;
  transition: font-size 0.2s ease;
}

.reaccionEmoji:hover {
  font-size: 40px;
}

.reaccionesContainer {
  display: flex;
  height: 48px;
  line-height: 48px;
  /* width: 450px; */
}

.reaccionContainer {
  display: inline-block;
  height: 26px;
  line-height: 26px;
}

.tooltipItem {
  overflow: visible;
  z-index: 9999999;
}