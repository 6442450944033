.dashboardTitleContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 24px;
}

.dashboardTitle {
  margin: 0;
}
