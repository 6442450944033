.customButton {
    border: none;
    background: white;
    margin: 0px 16px 0px 0px;
    padding: 0px;
    height: 33.6px;
    background-color: transparent;
  }
  .customButton:focus {
    outline: none;
  }
