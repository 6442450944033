body {
  margin: 0;
  line-height: 1.5;
  height: 100%;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none !important;
}

html {
  margin: 0;
  height: 100%;
  min-height: 100%;
}

#root {
  height: 100%;
}

.selectableText,
.selectableText p,
.selectableText h1,
.selectableText h2,
.selectableText h3,
.selectableText h4,
.selectableText ul,
.selectableText ol,
.selectableText li,
.selectableText div,
.selectableText span {
  -moz-user-select: text !important;
  -khtml-user-select: text !important;
  -webkit-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.dashboardContainer {
  height: 100%;
  padding: 3% 0 0 0;
}

/* @media screen and (max-width: 450px) {
    .dashboardContainer {
        padding-top: 6%;
    }
} */

@media screen and (min-width: 768px) {
  .dashboardContainer {
    height: 100%;
    padding: 3% 3% 0 3%;
  }
}

a.carousel-control-next,
a.carousel-control-prev {
  height: 50%;
  top: 25%;
}

#postEditorContainer div.DraftEditor-editorContainer,
#postEditorContainer div.DraftEditor-root,
#postEditorContainer div.public-DraftEditor-content {
  min-height: 100px;
}

.public-DraftEditorPlaceholder-inner {
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 14px;
}
