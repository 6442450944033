.box {
  margin: 48px 0 48px 0;
  background-color: #ffffff;
  padding: 24px 6% 24px;
  border: 1px;
  border-style: solid;
  border-color: #efefef;
  border-radius: 8px;
  -webkit-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  -moz-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}
