@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:700&display=swap');
.btnGeneralStyle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700 !important;
  -webkit-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  -moz-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.btnGeneralStyle:hover {
  -webkit-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  -moz-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.btn-outline-secondary {
  border-color: white;
}

.btn-outline-secondary:hover {
  border-color: white;
  background-color: white;
  color: #6c757d;
}

/* .btnGeneralStyle:hover {
  background-color: #1a88ff;
  border-color: #1a88ff;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(230, 230, 230, 1);
  -moz-box-shadow: 0px 0px 10px 1px rgba(230, 230, 230, 1);
  box-shadow: 0px 0px 10px 1px rgba(230, 230, 230, 1);
} */

.btnSizeLarge {
  height: 48px !important;
  font-size: 13px !important;
}

.btnSizeMedium {
  height: 40px !important;
  font-size: 13px !important;
}

.btnSizeSmall {
  height: 32px !important;
  font-size: 10px !important;
  /* border: 2px;
  font-size: 12px !important;
  font-weight: bold !important;
  height: 40px !important; */
}
