.alertContainer {
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000 !important;
  border-radius: 0px;
}

@media (min-width: 768px) {
}
