.filtroButton {
    cursor: pointer;
    display: inline-block;
    padding: 8px 16px;
    border-radius: 5px;
    background-color: #167bff;
    color: white;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 13px;
    font-weight: 700;
    vertical-align: middle;
    letter-spacing: normal;
    text-transform: uppercase;
}

.filtroButton:hover {
    background-color: #167bffaa;
}