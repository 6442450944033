@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

.imgClass {
  margin-top: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 40%;
}

.title {
  text-align: center;
  margin: 32px 32px 40px 32px;
}

.content {
  text-align: left;
}

.content a {
  color: #167bff;
}
