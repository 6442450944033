.refText,
.refText a {
  text-align: left;
}

.refText a {
  color: #167bff;
}

.buttonPosition {
  padding: 0 0 0px 0px !important;
}
