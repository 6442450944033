.AppPrivada {
  text-align: center;
  height: 100%;
}

.AppPrivada-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.AppPrivada-link {
  color: #61dafb;
}

@media (max-width: 767px) {
  .sidebarShadow {
    -webkit-box-shadow: 5px 0px 10px -5px rgba(196, 196, 196, 0.6);
    -moz-box-shadow: 5px 0px 10px -5px rgba(196, 196, 196, 0.6);
    box-shadow: 5px 0px 10px -5px rgba(196, 196, 196, 0.6);
  }
}

.dashboardContent {
  height: 100%;
  margin-top: 56px;
  background-color: #fafafa;
}

#sidebarToggler {
  position: absolute;
  display: block;
  z-index: 99999;
  top: 300;
  left: 300;
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .rightScreen {
    margin-left: 16.666667%;
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  #sidebarToggler {
    display: none;
  }
  #sidebarContainer {
    visibility: visible;
  }
  .dashboardContent {
    margin-top: 64px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
}
