.modalHeaderContainer {
  display: flex;
  justify-content: space-between;
  height: 33.33%;
  overscroll-behavior: contain;
}

.modalContentContainer {
  height: 33.33%;
}

.modalButtonsContainer {
  display: flex;
  justify-content: flex-start;
  height: 33.33%;
}

@media (min-width: 768px) {
  /* .modalPropio {
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 10px;
        width: 200px;
        height: 400px;
      } */

  .modalContent {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
}
