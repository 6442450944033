.userEditorContainer {
  border-radius: 6px;
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  background-color: #fff;
  color: #7f7f7f;
}

.postTextEditor {
  height: 40px;
  line-height: 40px;
  padding: 0 0 0 16px;
  text-align: left;
  background-color: #f2f2f2;
  border-radius: 25px;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.commentTextEditor {
  height: 32px;
  line-height: 32px;
  padding: 0 0 0 16px;
  text-align: left;
  background-color: #f2f2f2;
  border-radius: 25px;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.commentTextEditor:hover,
.postTextEditor:hover {
  background-color: #fafafa;
}
