.buttonPosition {
  padding: 0 0 0px 0px !important;
}

.selectedList {
  list-style: none;
  padding: 0px;
  text-align: left;
}

.selectedItem {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.deleteSelectedItem {
  cursor: pointer;
}

.terapeutaName {
  margin: 0px 0px 0px 16px;
}

.btnGeneralStyle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700 !important;
  font-size: 13px !important;
  height: 40px;
}

.containerButton {
  margin: 24px 0px 24px 0px;
  padding: 0px;
}

.containerSelectedList {
  padding: 0px;
}
