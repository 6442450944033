.buttonsSameLine {
    cursor: pointer;
    flex: 0 0 50%;
    max-width: 50%;
}

.selectClass {
    cursor: pointer;
    border-radius: 35px;
    height: 40px;
}
