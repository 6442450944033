.AppPublica {
  height: 100%;
  background-color: #fafafa;
}

.rowClass {
  background-color: #fafafa;
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .rightScreen {
    margin-left: 16.666667%;
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  #sidebarToggler {
    display: none;
  }
  #sidebarContainer {
    visibility: visible;
  }
  .dashboardContent {
    margin-top: 64px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
}
