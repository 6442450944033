@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:700&display=swap);
body {
  margin: 0;
  line-height: 1.5;
  height: 100%;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none !important;
}

html {
  margin: 0;
  height: 100%;
  min-height: 100%;
}

#root {
  height: 100%;
}

.selectableText,
.selectableText p,
.selectableText h1,
.selectableText h2,
.selectableText h3,
.selectableText h4,
.selectableText ul,
.selectableText ol,
.selectableText li,
.selectableText div,
.selectableText span {
  -webkit-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.dashboardContainer {
  height: 100%;
  padding: 3% 0 0 0;
}

/* @media screen and (max-width: 450px) {
    .dashboardContainer {
        padding-top: 6%;
    }
} */

@media screen and (min-width: 768px) {
  .dashboardContainer {
    height: 100%;
    padding: 3% 3% 0 3%;
  }
}

a.carousel-control-next,
a.carousel-control-prev {
  height: 50%;
  top: 25%;
}

#postEditorContainer div.DraftEditor-editorContainer,
#postEditorContainer div.DraftEditor-root,
#postEditorContainer div.public-DraftEditor-content {
  min-height: 100px;
}

.public-DraftEditorPlaceholder-inner {
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 14px;
}

/* Font Combination
Montserrat: for titles and subtitles
Source Sans Pro: for paragraphs */

/* Montserrat Font Weigth
- regular 400
- bold 700
- extra-bold 800 --not imported
- black 900 --not imported*/

/* font-family: 'Montserrat', ; */

/* Montserrat Font Weigth
- regular 400
- regular italic 400i
- bold 700 */

/* font-family: 'Source Sans Pro', ; */

h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #6f6f6f;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #6f6f6f;
  font-size: 16px;
}

/* p, */
select,
button,
small,
span,
label,
th,
td,
li,
li a,
a {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-user-select: none !important;
}

button.close span {
  font-size: 24px;
  font-weight: 700;
  color: #7f7f7f;
  vertical-align: sub;
}

p {
  font-size: 16px;
  font-weight: 400;
  color: #7f7f7f;
}

li {
  font-size: 16px;
  font-weight: 400;
  color: #7f7f7f;
}

label {
  font-size: 13px;
  font-weight: 700;
  color: #7f7f7f;
}

/* span {
  font-size: 13px;
  font-weight: 400;
  color: #7f7f7f;
} */

th {
  font-size: 16px;
  font-weight: 700;
  color: #6f6f6f;
}

td {
  font-size: 16px;
  font-weight: 400;
  color: #7f7f7f;
  cursor: text;
  -webkit-user-select: all !important; /* Chrome 49+ */ /* Firefox 43+ */
  -ms-user-select: all !important; /* No support yet */
  user-select: all !important;
}

li a {
  font-size: 16px;
  font-weight: 700;
  color: #7f7f7f !important;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

li.page-item.active span.page-link {
  font-size: 16px;
  font-weight: 700;
}

.table td,
.table th {
  vertical-align: middle;
}

.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  /* padding: 0px; */
  /* top: -16px !important; */
}

.AppPrivada {
  text-align: center;
  height: 100%;
}

.AppPrivada-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.AppPrivada-link {
  color: #61dafb;
}

@media (max-width: 767px) {
  .sidebarShadow {
    box-shadow: 5px 0px 10px -5px rgba(196, 196, 196, 0.6);
  }
}

.dashboardContent {
  height: 100%;
  margin-top: 56px;
  background-color: #fafafa;
}

#sidebarToggler {
  position: absolute;
  display: block;
  z-index: 99999;
  top: 300;
  left: 300;
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .rightScreen {
    margin-left: 16.666667%;
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  #sidebarToggler {
    display: none;
  }
  #sidebarContainer {
    visibility: visible;
  }
  .dashboardContent {
    margin-top: 64px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
}

.sidebar {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  background: #ffffff;
  border: 1px solid #efefef;
  z-index: 999999;
}

.logoContainer {
  height: 56px;
  margin-bottom: 32px;
  padding-left: 32px;
}

.logo {
  max-width: 100%;
  max-height: 100%;
}

.linkList {
  width: 100%;
  padding: 0px;
  list-style: none;
  text-align: left;
  font: Regular 24px/12px Arial;
  letter-spacing: 0;
  opacity: 1;
}

.linkItem {
  width: 100%;
  height: 48px;
  line-height: 48px;
}

.linkItem a {
  display: block;
  height: 100%;
  width: 100%;
  padding-left: 32px;
}

.linkItem a:hover {
  display: block;
  text-decoration: none;
  color: var(--colorFuentePrincipal);
  background: #fafafa 0% 0% no-repeat padding-box;
  opacity: 0.65;
}

.linkItemSelected,
.linkItemSelected a:hover {
  display: block;
  text-decoration: none;
  color: #167bff !important;
  background: #efefef 0% 0% no-repeat padding-box !important;
  font-weight: bold;
  opacity: 0.65;
  border-left: 2px solid #007bff;
}

@media (min-width: 576px) {
  .sidebarTitle {
    line-height: 56px;
  }
}

@media (min-width: 768px) {
  .linkList {
    margin-top: 0;
  }
  .linkItem a {
    padding-left: 16px;
  }
  .logoContainer {
    padding-left: 16px;
  }
  .sidebar {
    z-index: 100;
  }
}

@media (min-width: 992px) {
  .logoContainer {
    height: 64px;
    margin-bottom: 24px;
    padding-left: 24px;
  }
  .linkItem a {
    padding-left: 24px;
  }
}

@media (min-width: 1200px) {
}

.supportButton_btnGeneralStyle__2hzud {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700 !important;
  border: solid 1px;
  color: #7f7f7f !important;
  /* background-color: #fafafa; */
  /* border-color: #dfdfdf; */
  border-color: #dfdfdf;
  background-color: #ffffff;
  border-radius: 35px;
  height: 40px !important;
  font-size: 14px !important;
}

a.supportButton_btnGeneralStyle__2hzud:hover {
  border-color: #25d366;
  box-shadow: 0px 0px 10px 1px rgba(230, 230, 230, 1);
}

.feedbackButton_icon__2RcKX {
    width: 40px;
    height: 40px !important;
    padding: 1px;
    background-color: #ffffff;
    border-radius: 35px;
    border: solid 1px #dfdfdf;
}

.feedbackButton_icon__2RcKX:hover {
    border: 1px solid #ff6b6b;
    box-shadow: 0px 0px 10px 1px rgba(230, 230, 230, 1);
}

.feedbackButton_iconImg__2ow7r {
    width: 20px;
    height: 20px;
}
.feedbackForm_novo__1mlTV {
    color: #595959;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
}

.feedbackForm_labs__QC2z9 {
    color: #ff6b6b;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.feedbackForm_feedbackTextarea__1M35M {
    outline: none;
    border: solid 2px #efefef;
    /* width: 100%; */
    padding: 1px;
}

.feedbackForm_novolabs__3FTQx {
    width: 150px;
    height: 40px;
}

.feedbackForm_feedbackBox__2Oouk {
    display: flex;
    justify-content: center;
    margin-top: -16px;
    margin-bottom: 64px;
}

.feedbackForm_closetag__3fIYe {
    align-self: center;
}

.feedbackForm_feedbackOptions__32Klb img {
    height: 50px;
    width: 50px;
}

.feedbackForm_feedbackOptions__32Klb button:hover {
    cursor: pointer;
    height: 85px;
    width: 85px;
    margin-left: -5px;
}

.feedbackForm_feedbackOptions__32Klb button {
    border-radius: 16px;
    width: 80px;
    height: 80px;
    background-color: #FAFAFA;
    border: 1px transparent;
    box-shadow: 4px 12px 12px -8px #777;
    transition: ease 0.1s;
}

.feedbackForm_buttonsContainer__kzMit {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 120px;
}

.feedbackForm_buttonsContainer__kzMit button span {
    margin: auto;
}


/*Hover message*/

.feedbackForm_tooltip__9p560 {
    background-color: #ff6b6b !important;
}
.modalPropio_modalPropio__3zYqe {
  position: absolute;
  /* estaba comentado por las dudas*/
  top: 50%;
  left: 50%;
  padding: 10px;
  width: 200px;
  height: 400px;
  /* z-index: 9999999999; */
}

.modalPropio_modalHeaderContainer__nrzFd {
  display: flex;
  justify-content: space-between;
  height: 33.33%;
}

.modalPropio_modalContentContainer__1kDYy {
  height: 33.33%;
}

.modalPropio_modalButtonsContainer__3S-BY {
  display: flex;
  justify-content: flex-start;
  height: 33.33%;
}

.spinner_small__1AfZl {
    margin: 24px !important;
}

.spinner_extraSmall__3KT2n {
    margin: 0px 0px 0px 0px !important;
}

.spinner_forButtonWhite__1N8x2 {
    margin: 0px 8px 0px 0px !important;
    color: white !important;
}

.spinner_forButtonBlue__12CQT {
    margin: 0px 8px 0px 0px !important;
    color: white !important;
}
.alertPropia_alertContainer__2I1AY {
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000 !important;
  border-radius: 0px;
}

@media (min-width: 768px) {
}

.userNavbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 4%;
    white-space: nowrap;
    /* margin-right: 72px; */
}

.userNameContainer {
    padding-right: 8px;
    font: Regular 16px/30px Arial;
    letter-spacing: 0;
    opacity: 1;
}

.navbarAvatarContainer {
    height: 24px;
    width: 24px;
    margin-left: 8px;
}

.avatar {
    max-height: 100%;
    max-width: 100%;
    border-radius: 50%;
}

button#dropdown-basic.dropdown-toggle.btn.btn-none {
    padding: 0px;
}

button#dropdown-basic.dropdown-toggle.btn.btn-none::after {
    display: none;
}

button#dropdown-basic.dropdown-toggle.btn.btn-none:focus,
button#dropdown-basic.dropdown-toggle.btn.btn-none:active {
    outline: 0;
    box-shadow: 0 0 0 0 #ffffff;
}

#notificationItem:hover {
    color: #212529AA;
}

#notificationContainer {
    position: relative;
}

#notificationBadge {
    position: absolute;
    top: 0px;
    right: 25%;
}

.avatar_avatarContainerXS__QiDmW {
    height: 24px;
    width: 24px;
}

.avatar_avatarContainerS__3KKTl {
    height: 32px;
    width: 32px;
}

.avatar_avatarContainerM__uuDra {
    height: 40px;
    width: 40px;
}

.avatar_avatarContainerL__3NMaW {
    height: 48px;
    width: 48px;
}

.avatar_avatarContainerXL__1FVFc {
    height: 80px;
    width: 80px;
}

.avatar_avatar__1hlwy {
    object-fit: cover;
    object-position: center center;
    border-radius: 50%;
}


/* .avatar {
    border-radius: 50%;
    background-size: cover;
} */
#notificationDropdown_notificationContainer__2Cw8T {
    position: relative;
}

#notificationDropdown_notificationBadge__3kyD- {
    position: absolute;
    top: 0px;
    right: 25%;
}

#notificationDropdown_notificationItem__1SvNb {
    cursor: pointer;
}

#notificationDropdown_notificationItem__1SvNb:hover {
    color: #212529AA;
}

.notificationDropdown_notifDropdown__2viPb {
    /* position: fixed;
    top: 56px!important;
    right: 0;
    left: 0; */
    max-height: 90vh;
    height: 90vh;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
    /* overflow-x: hidden; */
    width: 100vw;
}

@media (min-width: 992px) {
    .notificationDropdown_notifDropdown__2viPb {
        max-height: 80vh;
        height: 80vh;
        overflow-y: auto;
        width: 30vw;
        margin: 0;
        }
        
}
.notification_notificacionContainerLeida__12cQy {
    max-width: 50px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
}

.notification_notificationContainer__3lly1 {
    display: flex;
    align-items: flex-start;
    margin: 16px 0;
    padding: 0 8px;
    border-radius: 4px;
    text-align: left;
}

.notification_notificationContainer__3lly1:hover {
    background-color: #FAFAFA;
    cursor: pointer;
}

#notification_notificacionMensaje__R6VfZ {
    margin-left: 8px;
    max-width: 25px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

@media (min-width: 768) {
    .notification_notificationContainer__3lly1 {
        max-width: 400px;
        width: 400px;
    }
}
.emptyState_emptyStateContainer__5VE_K {
  margin: auto;
}

.emptyState_emptyStateImg__1CYTo {
  max-width: 20%;
  margin-bottom: 24px;
}

.emptyState_leyendaUno__2UZ_w {
  margin-bottom: 0px;
}
.emptyState_leyendaDos__3_No0 {
  margin-bottom: 32px;
}

@media screen and (max-width: 767px) {
  .emptyState_emptyStateImg__1CYTo {
    max-width: 35%;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 450px) {
  .emptyState_emptyStateImg__1CYTo {
    max-width: 60%;
    margin-bottom: 24px;
  }
}

.toast_customToast__1G_Uj {
    border-top: 3px solid #167BFF;
    opacity: 1!important;
    background-color: white;
}
.navbarDonadio {
  display: flex;
  justify-content: space-between;
  top: 0px;
  height: 56px;
  width: 100%;
  z-index: 1000;
  background: #ffffff;
  border: 1px solid #efefef;
}

@media (min-width: 768px) {
  .navbarDonadio {
    justify-content: flex-end;
    width: 83.34%;
  }
}

@media (min-width: 992px) {
  .navbarDonadio {
    height: 64px;
  }
}

@media (min-width: 1200px) {
}

.AppPublica {
  height: 100%;
  background-color: #fafafa;
}

.rowClass {
  background-color: #fafafa;
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .rightScreen {
    margin-left: 16.666667%;
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  #sidebarToggler {
    display: none;
  }
  #sidebarContainer {
    visibility: visible;
  }
  .dashboardContent {
    margin-top: 64px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
}

.login_refText__1xFu2,
.login_refText__1xFu2 a {
  text-align: left;
}

.login_refText__1xFu2 a {
  color: #167bff;
}

.login_buttonPosition__1sdFt {
  padding: 0 0 0px 0px !important;
}

.formContainer_formBox__1ioqM {
  margin: 40px 0 40px 0;
  background-color: #ffffff;
  padding: 24px 6% 24px;
  border: 1px;
  border-style: solid;
  border-color: #efefef;
  border-radius: 6px;
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.formContainer_boxTitle__13O0e {
  text-align: left;
  margin-bottom: 32px;
}

.formInput_inputLabel__ueExZ {
  float: left;
}

.formInput_inputError__3QqZp {
  border-color: #cb2e25 !important;
  color: #cb2e25 !important;
}

.formInput_labelError__31yzw {
  color: #cb2e25 !important;
}

.formInput_inputSelect__Hv6Cc {
  -webkit-user-select: text !important; /* Chrome, Opera, Safari */ /* Firefox 2+ */
  -ms-user-select: text !important; /* IE 10+ */
  user-select: text !important; /* Standard syntax */

}
.formButton_btnGeneralStyle__2vSlK {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700 !important;
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.formButton_btnGeneralStyle__2vSlK:hover {
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.formButton_btn-outline-secondary__19Nr_ {
  border-color: white;
}

.formButton_btn-outline-secondary__19Nr_:hover {
  border-color: white;
  background-color: white;
  color: #6c757d;
}

/* .btnGeneralStyle:hover {
  background-color: #1a88ff;
  border-color: #1a88ff;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(230, 230, 230, 1);
  -moz-box-shadow: 0px 0px 10px 1px rgba(230, 230, 230, 1);
  box-shadow: 0px 0px 10px 1px rgba(230, 230, 230, 1);
} */

.formButton_btnSizeLarge__2ZFWu {
  height: 48px !important;
  font-size: 13px !important;
}

.formButton_btnSizeMedium__FbETh {
  height: 40px !important;
  font-size: 13px !important;
}

.formButton_btnSizeSmall__3eNWp {
  height: 32px !important;
  font-size: 10px !important;
  /* border: 2px;
  font-size: 12px !important;
  font-weight: bold !important;
  height: 40px !important; */
}

.inputMessage_messageClass__2y9yl {
  font-size: 13px;
  color: #7f7f7f;
  text-align: left;
}

.inputMessage_spanError__2BzOI {
  color: #cb2e25;
}

.signUp_custom-control-label__1V_4Q {
  font-weight: 400;
}

.signUp_refText__pBozG,
.signUp_refText__pBozG a {
  text-align: left;
}

.signUp_refText__pBozG a {
  color: #167bff;
}

.signUp_buttonPosition__FrdGG {
  padding: 0 0 0px 0px !important;
}

.signUp_noHayAvatar__2YUxP {
  padding-right: 0px;
  padding-left: 24px;
  display: flex;
  flex-direction: row;
  align-self: center;
}

.signUp_avatarContainer__Ts4A2 {
  padding-right: 0px;
  padding-left: 24px;
  display: flex;
  flex-direction: row;
}

.signUp_avatar__2DpTN {
  margin-right: 16px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 1199px) {
  .signUp_noHayAvatar__2YUxP {
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 16px;
  }
}

@media (max-width: 1199px) {
  .signUp_avatarContainer__Ts4A2 {
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 16px;
  }
}

@media (max-width: 1199px) {
  .signUp_containerAvatarMax__9uucf {
    flex-direction: column;
  }
}

/* Step 1 - AM Paciente */
@media (max-width: 1199px) {
  .signUp_listFilePreview__3i3dP {
    margin-top: 16px;
  }
}

.formSelect_inputLabel__2Q2ca {
  float: left;
}

.formSelect_inputError__3Eg96 {
  border-color: #cb2e25 !important;
  color: #cb2e25 !important;
}

.formSelect_labelError__fY6dD {
  color: #cb2e25 !important;
}

input[type='file'] {
  display: none;
}
.formInputFile_customFileUpload__aqX1x {
  border: 1px solid #ccc;
  display: inline-block;
}

.formInputFile_btnBootstrap__2XxNP {
  border: 2px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 13px;
  height: 48px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  align-items: center;
  place-content: center;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  margin: 0px;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #007bff;
  border-color: #007bff;
  width: 100%;
  cursor: pointer;
}

.login_refText__1ItF-,
.login_refText__1ItF- a {
  text-align: left;
}

.login_refText__1ItF- a {
  color: #167bff;
}

.login_buttonPosition__24PKi {
  padding: 0 0 0px 0px !important;
}

.box_box__3snJP {
  margin: 48px 0 48px 0;
  background-color: #ffffff;
  padding: 24px 6% 24px;
  border: 1px;
  border-style: solid;
  border-color: #efefef;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.confirmationMailPassword_imgClass__7LuO2 {
  margin-top: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 40%;
}

.confirmationMailPassword_title__2az6Z {
  text-align: center;
  margin: 32px 32px 40px 32px;
}

.confirmationMailPassword_content__1g55m {
  text-align: left;
}

.confirmationMailPassword_content__1g55m a {
  color: #167bff;
}

/* .label-label {
    float: left;
}

.form-control:focus {
    border-color: #167bff;
    box-shadow: none;
}

.custom-control-label {
    font-weight: 400;
}

.label-error {
    color: #cb2e25;
    float: left;
}

.span-error {
    color: #cb2e25;
    float: right;
}

.error {
    border-color: #cb2e25;
    color: #cb2e25;
}

.delete-span {
    color: red;
    font-size: 2rem;
}

.terapeutas li {
    float: left;
    text-decoration: none;
} */

.dropdown_inputLabel__1QQuA {
  float: left;
}

.backButton_customButton__33Y-r {
    border: none;
    background: white;
    margin: 0px 16px 0px 0px;
    padding: 0px;
    height: 33.6px;
    background-color: transparent;
  }
  .backButton_customButton__33Y-r:focus {
    outline: none;
  }

.dashboardTitle_dashboardTitleContainer__1BYwL {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 24px;
}

.dashboardTitle_dashboardTitle__1wsf1 {
  margin: 0;
}

.editarTerapias_listTerapeutas__2iUWy ul {
  display: flex;
  list-style: none;
}

.editarTerapias_spanContainer__1HXEI {
  display: flex;
  justify-content: space-around;
  float: right;
  width: 50%;
}

.editarTerapias_buttonPosition__14McW {
  padding: 0 0 0px 0px !important;
}

.editarTerapias_containerSelectedList__2nDrX {
  padding: 0px;
}

.editarTerapias_selectedList__1BMaf {
  list-style: none;
  padding: 0px;
  text-align: left;
}

.editarTerapias_selectedItem__2gOjP {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.editarTerapias_deleteSelectedItem__Xnvor {
  cursor: pointer;
}

.editarTerapias_terapeutaName__1WbGf {
  margin: 0px 0px 0px 16px;
}

.formTextArea_inputLabel__ooH5q {
  float: left;
}


.ConfirmationSignUp_imgClass__okfbF {
  margin-top: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 40%;
}

.ConfirmationSignUp_title__2S0RD {
  text-align: center;
  margin: 32px 32px 40px 32px;
}

.ConfirmationSignUp_content__2J89W {
  text-align: left;
}

.ConfirmationSignUp_content__2J89W a {
  color: #167bff;
}

.dashboardPacientes_customButtonLeft__3r1yx {
  border: none;
  background: white;
  margin: 0px 0px 0px 8px;
  padding: 0px;
}
.dashboardPacientes_customButtonLeft__3r1yx:focus {
  outline: none;
}

.dashboardPacientes_customButtonRight__2ktN0 {
  border: none;
  background: white;
  margin: 0px 0px 0px 16px;
  padding: 0px;
}
.dashboardPacientes_customButtonRight__2ktN0:focus {
  outline: none;
}

.dashboardPacientes_customSvg__1w8y6 {
  height: 32px !important;
  width: 32px !important;
}

.dashboardPacientes_customSvgDelete__2sbCh:hover {
  color: #cb2e25 !important;
}

.dashboardPacientes_customSvgEdit__3zoVU:hover {
  color: #0079e1 !important;
}

.dashboardPacientes_searchContainer__313Tp {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 24px 0px;
}

.dashboardPacientes_buttonEmptyState__2n2gI {
  display: inline-block;
}

.dashboardPacientes_searchBoxCon__1xU_E {
  padding: 0px 0px 0px 0px;
}

.dashboardPacientes_btnCon__2Ivwq {
  padding: 0px 0px 0px 0px;
  height: 40px;
}

.dashboardPacientes_containerActions__1lHmO {
  display: flex;
  flex-flow: nowrap;
}

.dashboardPacientes_containerActionsIcons__sFuvA {
  display: flex;
  flex-flow: nowrap;
  margin-left: 16px;
}

.dashboardPacientes_containerActionsIcons__sFuvA a {
  width: 32px;
  height: 40px;
}

@media screen and (max-width: 768px) {
  .dashboardPacientes_btnCon__2Ivwq {
    margin-top: 16px;
  }
}

.buscador_buscadorContainer__e2PkK {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  border: 1px solid #efefef;
  border-radius: 6px;
  background-color: #fff;
  /* -webkit-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  -moz-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1); */
}

.buscador_buscador__39ZuQ {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 6px;
  padding: 4px 8px 4px 12px;
  opacity: 1;
}

.buscador_icon__ZiSuS {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grilla_grilla__CVvcM {
  /* width: 93.5031847%; */
  background-color: #ffffff;
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.buttonFeedPaciente_btnGeneralStyle__3Vz2e {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700 !important;
  height: 40px !important;
  font-size: 13px !important;
  width: 140px;
}

.buttonFeedPaciente_cursorDefaultClass__1I_D_ {
  cursor: default;
}

.dropdownTerapiaModal_selectedList__2LfMQ {
  list-style: none;
  padding: 0px;
  text-align: left;
}

.dropdownTerapiaModal_selectedItem__19Y4V {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.dropdownTerapiaModal_deleteSelectedItem__3a5dL {
  cursor: pointer;
}

.dropdownTerapiaModal_containerButton__1wVuh {
  margin-bottom: 24px;
}

.dropdownTerapiaModal_btnGeneralStyle__2hheP {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700 !important;
  font-size: 13px !important;
  height: 40px;
}

.dropdownTerapiaModal_terapeutaName__3dUO7 {
  margin: 0px 0px 0px 16px;
}

.dashboardTerapeuta_dashboardTerapeuta__IrNfD {
  height: 100%;
}

.dashboardTerapeuta_customButton__2RYKD {
  border: none;
  background: white;
  margin: 0px 4px 0px 4px;
  padding: 0px;
}
.dashboardTerapeuta_customButton__2RYKD:focus {
  outline: none;
}

.dashboardTerapeuta_customSvg__1rubY {
  height: 32px !important;
  width: 32px !important;
}

.dashboardTerapeuta_customSvgDelete__2x0X6:hover {
  color: #cb2e25 !important;
}

.dashboardTerapeuta_customSvgEdit__1crAr:hover {
  color: #0079e1 !important;
}

.dashboardTerapeuta_searchContainer__3-keF {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 24px 0px;
}

.dashboardTerapeuta_searchBoxCon__392wZ {
  padding: 0px 0px 0px 0px;
}

.dashboardTerapeuta_link_info__1iZQh {
  text-decoration: none;
  color: #7f7f7f;
}

.dashboardTerapeuta_link_info__1iZQh:hover {
  text-decoration: underline;
  color: #0079e1;
}

/* .descripcion{
    margin:auto;
    justify-content: start;
} */
/* .standardDescription {
  text-align: left;
  justify-content: start;
  overflow: clip;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  height: 66%;
} */
.terapiaCard_terapiaCard__3uvlR {
  height: 240px;
  border-radius: 6px;
  border-color: #efefef;
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.terapiaCard_terapiaContainer__1CdAR {
  position: relative;
  float: left;
  display: flex;
  flex-direction: column;
}

.terapiaCard_terapiaButtons__399pD {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 87%;
}
/* .titleClass {
  text-align: left;
} */

/* styles for '...' */
.terapiaCard_titleBlockWithText__2lONM {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 2.4em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}

.terapiaCard_descriptionBlockWithText__9Rfzq {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 6em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}
/* create the ... */
.terapiaCard_titleBlockWithText__2lONM:before,
.terapiaCard_descriptionBlockWithText__9Rfzq:before {
  /* points in the end */
  content: '...';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}
/* hide ... if we have text, which is less than or equal to max lines */
.terapiaCard_titleBlockWithText__2lONM:after,
.terapiaCard_descriptionBlockWithText__9Rfzq:after {
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}

.terapiaCard_customButton__3wcQH {
  border: none;
  background: white;
  margin: 0px 4px 0px 4px;
  padding: 0px;
}
.terapiaCard_customButton__3wcQH:focus {
  outline: none;
}

.terapiaCard_customSvg__2gVzc {
  height: 32px !important;
  width: 32px !important;
}

.terapiaCard_customSvgDelete__CaPWw:hover {
  color: #cb2e25 !important;
}

.terapiaCard_customSvgEdit__2930X:hover {
  color: #0079e1 !important;
}

.terapiaCard_buttonsContainer__uYmLH {
  height: 46px;
}

.dashboardTerapia_buttonEmptyState__31Ud0 {
  display: inline-block;
}

.dashboardTerapia_searchContainer__14JR_ {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 24px 0px;
}

.dashboardTerapia_cards__3W4Ex {
  padding: 8px 8px 8px 8px;
}

.dashboardTerapia_searchBoxCon__1VAQQ {
  padding: 0px 0px 0px 0px;
}

.dashboardTerapia_btnCon__25iNi {
  padding: 0px 0px 0px 0px;
  height: 40px;
}

@media screen and (max-width: 768px) {
  .dashboardTerapia_btnCon__25iNi {
    margin-top: 16px;
  }
}

.dashboardAprobaciones_customButton__3_XX_ {
  border: none;
  background: white;
  margin: 0px 4px 0px 4px;
  padding: 0px;
}
.dashboardAprobaciones_customButton__3_XX_:focus {
  outline: none;
}

.dashboardAprobaciones_customSvg__1p9DW {
  height: 32px !important;
  width: 32px !important;
}

.dashboardAprobaciones_customSvgDisapprove__3WDwl:hover {
  color: #cb2e25 !important;
}

.dashboardAprobaciones_customSvgApprove__2BC4j:hover {
  color: #0079e1 !important;
}

.groupedPosts_fadeIn__1xf8U {
    animation: groupedPosts_fadeIn__1xf8U ease 1s;
    -webkit-animation: groupedPosts_fadeIn__1xf8U ease 1s;
    -moz-animation: groupedPosts_fadeIn__1xf8U ease 1s;
    -o-animation: groupedPosts_fadeIn__1xf8U ease 1s;
    -ms-animation: groupedPosts_fadeIn__1xf8U ease 1s;
    }
    @keyframes groupedPosts_fadeIn__1xf8U {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-webkit-keyframes groupedPosts_fadeIn__1xf8U {
    0% {opacity:0;}
    100% {opacity:1;}
    }

    .groupedPosts_borrarTodosFiltros__rY-Ig {
        font-size: 16px;
        padding: 4px 8px;
        margin: 0px 0px 8px 0px;
        background-color: transparent;
        color: red;
    }
    
    .groupedPosts_borrarTodosFiltros__rY-Ig:hover {
        cursor: pointer;
        background-color: transparent;
        text-decoration: underline;
    }
.post_box__NG9DW {
  margin: 32px 0 32px 0;
  background-color: #ffffff;
  border: 1px;
  border-style: solid;
  border-color: #efefef;
  border-radius: 6px;
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.post_textoClass__1WloU {
  margin-bottom: 0px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.post_secondRowClass__3obml {
  margin: 0px 0px 0px 0;
  padding: 16px 0px 8px 0px;
}

.post_inputClass__1FPPm {
  text-align: left;
  vertical-align: top !important;
  height: 100px;
  max-height: -webkit-min-content;
  max-height: -moz-min-content;
  max-height: min-content;
  width: 100%;
}

.post_inputClass__1FPPm.post_break__2Xs4D {
  word-wrap: break-word;
  word-break: break-all;
}

.post_inputClass__1FPPm::-webkit-input-placeholder {
  text-align: left;
  vertical-align: top !important;
}

.post_inputClass__1FPPm:-ms-input-placeholder {
  text-align: left;
  vertical-align: top !important;
}

.post_inputClass__1FPPm::-ms-input-placeholder {
  text-align: left;
  vertical-align: top !important;
}

.post_inputClass__1FPPm::placeholder {
  text-align: left;
  vertical-align: top !important;
}

textarea {
  resize: none;
  border: 0px;
}

textarea:focus,
input:focus,
button:focus,
select:focus {
  outline: none;
}

.post_selectClass__mBGEM {
  border-radius: 35px;
  height: 40px;
}

.post_containerPersonalData__3QSLV {
  margin-bottom: 16px;
}

.post_containerPersonalData__3QSLV p {
  font-weight: bold;
  margin-bottom: 0px;
}

.post_containerPersonalData__3QSLV small {
  font-size: 12px;
}
/* 
textarea:focus,
input:focus,
button:focus,
select:focus {
    outline: none;
} */

.post_colButtonClass__BSktc {
  padding: 0px;
}

.post_postActions__Pjndv {
  margin: 16px 0;
}

.post_customButton__WYG7d {
  border: none;
  background: white;
  margin: 0px 4px 0px 4px;
  padding: 0px;
  align-self: flex-start;
}

.post_customButton__WYG7d:focus {
  outline: none;
}

.post_viewMoreShadow__3-GWY {
  background-image: linear-gradient(to bottom, transparent 56px, white);
}

.post_customSvgDelete__3mGYy:hover {
  color: #cb2e25 !important;
}

.post_avatarColumn__3rFd0 {
  padding: 0px 0px 0px 15px !important;
}

.post_inputContainerClass__1EAqS {
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
}

.post_nombrePacienteClass__88SIo {
  display: inline-block;
  text-align: left;
  margin-bottom: 0px;
}

.post_smallAntiguedad__3n5mf {
  display: block;
  text-align: start;
}

.post_firstRowClass__2KLJ4 {
  background-color: #ffffff;
  margin: 16px 0px 0 0px;
  padding-left: 16px;
  padding-right: 8px;
  border-width: 1px;
  border: 1px;
  border-bottom-style: solid;
  border-color: #efefef;
}

@media (min-width: 768px) {
  .post_avatarColumn__3rFd0 {
    padding: 0px 0px 0px 15px !important;
    margin-top: 6px;
  }
  .post_inputContainerClass__1EAqS {
    text-align: left;
    margin-bottom: 16px;
    padding: 0px 8px 0px 16px;
  }
  .post_customButton__WYG7d {
    align-self: unset;
  }
  .post_firstRowClass__2KLJ4 {
    margin: 16px 0px 0px 0px;
    padding-right: 0px;
  }
  .post_nombrePacienteClass__88SIo {
    display: inline !important;
  }
  .post_smallAntiguedad__3n5mf {
    margin-left: 8px;
    display: inline-block;
    text-align: start;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

.common_customEditorStyles__MaYDV span {
    font-size: inherit;
    font-family: 'Source Sans Pro', sans-serif;
    color: #7f7f7f;
    line-height: 1.5;
}

.common_commentStyles__2YlbM span {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #7f7f7f;
    line-height: 1.5;
}

.common_paginatedText__by6IV {
    max-height: 128px;
    overflow: hidden;
}

.badge_badgeTerapia__3bDOd {
  /* margin: 0 0 0 8px; */
  padding: 4px 8px 4px 8px;
}

.badge_spanSecondary__s0bYF {
  background-color: #efefef !important;
  color: #454545 !important;
  margin: 0px 4px 0px 4px;
}

.groupedComments_spinnerContainer__1VZ1u {
    border: 1px;
    border-bottom-style: solid;
    border-color: #efefef;
}

.groupedComments_secondRowClass__qf9Iq {
    margin: 0px 0px 0px 0;
    padding: 8px 0px 8px 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.groupedComments_avatarColumn__vYhNZ {
    display: flex;
    flex-direction: column;
    /* padding: 0px; */
    justify-content: flex-end;
    align-items: center;
}

.groupedComments_commentContentContainer__27rAC {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding-left: 0px; */
}

.groupedComments_textAreaContainer__3nZFC {
    max-height: 364px;
    width: 100%;
    overflow: auto;
    word-wrap: break-word;
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
}

.groupedComments_textAreaContainerMobile__2-ZqO {
    width: 100%;
    padding: 4px 8px 0 0;
}

.groupedComments_textAreaClass__Uoh7Z {
    text-align: left;
    /* height: 40px;
    max-height: 240px; */
    /* max-height: min-content; */
    width: 100%;
}

/* .textAreaClass::placeholder {
    text-align: left;
    vertical-align: top !important;
} */

.groupedComments_colButtonClass__1D40w {
    display: flex;
    flex-direction: column;
    padding: 0px;
    justify-content: flex-end;
    align-items: center;
}

.groupedComments_loadMoreContainer__26fqF {
    padding: 0 16px 0 65px;
    text-align: left;
    /* display: flex;
    justify-content: space-between; */
}

.groupedComments_linkLoadMore__9DwRn {
    color: #007bff !important;
    cursor: pointer;
    font-size: 12px;
    line-height: 12px;
}

.groupedComments_linkLoadMore__9DwRn:hover {
    text-decoration: underline !important;
}

@media only screen and (max-width: 890px) {
    .groupedComments_avatarColumn__vYhNZ {
        padding: 0px 0px 0px 15px !important;
    }
    .groupedComments_textAreaContainer__3nZFC {
        padding: 4px 8px 0px 16px;
    }
}

@media only screen and (max-width: 590px) {
    .groupedComments_avatarColumn__vYhNZ {
        padding: 0px 0px 0px 15px !important;
    }
    .groupedComments_textAreaContainer__3nZFC {
        padding: 4px 8px 0px 20px;
    }
}

@media only screen and (max-width: 490px) {
    .groupedComments_avatarColumn__vYhNZ {
        padding: 0px 0px 0px 15px !important;
    }
    .groupedComments_textAreaContainer__3nZFC {
        padding: 4px 8px 0px 24px;
    }
}

@media only screen and (max-width: 420px) {
    .groupedComments_avatarColumn__vYhNZ {
        padding: 0px 0px 0px 15px !important;
    }
    .groupedComments_textAreaContainer__3nZFC {
        padding: 4px 8px 0px 32px;
    }
}

@media only screen and (max-width: 767px) {
    .groupedComments_loadMoreContainer__26fqF {
        padding: 0 0px 0 32px;
    }
}

@media (min-width: 768px) {
    .groupedComments_linkLoadMore__9DwRn {
        font-size: 14px;
    }
}
.postComment_inputContainerClass__2LMGS {
    text-align: left;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.postComment_containerPersonalData__2QzKA {
    margin-bottom: 8px;
}

.postComment_containerPersonalData__2QzKA p {
    font-weight: bold;
    margin-bottom: 0px;
}

.postComment_textParagraph__1H3k1 {
    margin-bottom: 0px;
    font-size: 14px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    /* padding-right: 28px; */
}

.postComment_colAvatar__Ouy9Y {
    /* margin-top: 6px; */
    padding: 0px 15px 0px 0px;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
}

.postComment_customButton__2PzEO {
    border: none;
    background: white;
    padding: 4px 0px 0px 0px;
    align-self: flex-start;
}

.postComment_customButton__2PzEO:focus {
    outline: none;
}

.postComment_customSvgDelete__SuKT0:hover {
    color: #cb2e25 !important;
}

.postComment_firstRowClass__z4Udd {
    background-color: #FDFFFD;
    padding: 16px 16px 16px 32px;
    margin: 0px 0px 0px 0px;
    border-width: 1px;
    border: 1px;
    border-bottom-style: solid;
    border-color: #efefef;
    flex-wrap: nowrap !important;
}

.postComment_nombrePacienteClass__2KDfR {
    display: inline-block;
    text-align: left;
    margin-bottom: 0px;
}

.postComment_smallAntiguedad__1tHZ_ {
    display: block;
    text-align: start;
}

@media (min-width: 768px) {
    .postComment_firstRowClass__z4Udd {
        background-color: #ffffff;
        padding: 16px 8px 16px 65px;
        margin: 0px 0px 0px 0px;
        border-width: 1px;
        border: 1px;
        border-bottom-style: solid;
        border-color: #efefef;
        flex-wrap: nowrap !important;
    }
    .postComment_nombrePacienteClass__2KDfR {
        display: inline !important;
        text-align: center;
    }
    .postComment_smallAntiguedad__1tHZ_ {
        margin-left: 8px;
        display: inline-block;
        text-align: start;
    }
    .postComment_customButton__2PzEO {
        border: none;
        background: white;
        padding: 0px;
    }
    .postComment_textParagraph__1H3k1 {
        margin-bottom: 0px;
        font-size: 14px;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        padding-right: 28px;
    }
}


/* @media only screen and (max-width: 890px) {
  .inputContainerClass {
    padding: 0px 0px 0px 16px !important;
  }
}

@media only screen and (max-width: 590px) {
  .inputContainerClass {
    padding: 0px 0px 0px 20px !important;
  }
}

@media only screen and (max-width: 490px) {
  .inputContainerClass {
    padding: 0px 0px 0px 24px !important;
  }
}

@media only screen and (max-width: 420px) {
  .inputContainerClass {
    padding: 0px 0px 0px 32px !important;
  }
} */


/* @media only screen and (max-width: 717px) {
    .colAvatar {
        padding: 0px 0px 0px 0px !important;
    }
    .inputContainerClass {
        padding: 0px 0px 0px 15px !important;
    }
} */
.modalWithoutFooter_modalPropio__30PEG {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 10px;
    width: 200px;
    height: 400px;
  }
  
  .modalWithoutFooter_modalHeaderContainer__1MijL {
    display: flex;
    justify-content: space-between;
    height: 33.33%;
  }
  
  .modalWithoutFooter_modalContentContainer__1Gfch {
    height: 33.33%;
  }
  
  .modalWithoutFooter_modalButtonsContainer__3Lv6k {
    display: flex;
    justify-content: flex-start;
    height: 33.33%;
  }
  
  .modalWithoutFooter_modalBody__tMWS9 {
    max-height: 460px;
    height: 460px;
    overflow-y: auto;
    scroll-behavior: contain;
  }

.comentarioReaccionButton_tooltipContainer__3iDU0 {
  padding: 8px 16px;
  height: 56px;
  background: black;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 35px;
  box-shadow: 0px 0px 8px 0px rgba(222, 222, 222, 1);
  display: flex;
  align-items: center;
  z-index: 10;
}

.comentarioReaccionButton_reaccionesButtonContainerSinReaccion__1TwSF {
  background-color: #fafafa;
  /* padding: 8px 16px; */
  border-radius: 35px;
  display: flex;
  height: 40px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.comentarioReaccionButton_reaccionesButtonContainerSinReaccion__1TwSF:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}

.comentarioReaccionButton_reaccionesButtonContainerConReaccion__21TN7 {
  background-color: #167bff;
  /* padding: 8px 16px; */
  border-radius: 35px;
  display: flex;
  height: 40px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.comentarioReaccionButton_reaccionesButtonContainerConReaccion__21TN7:hover {
  background-color: #3a8fff;
  cursor: pointer;
}

.comentarioReaccionButton_reaccionesButton__Dj62M {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.comentarioReaccionButton_reaccionesButtonContainerConReaccion__21TN7 div p,
.comentarioReaccionButton_reaccionesButtonContainerSinReaccion__1TwSF div p {
  margin: 0px 0px 0px 8px;
}

.comentarioReaccionButton_reaccionesButtonContainerConReaccion__21TN7 div p {
  color: white;
}

.comentarioReaccionButton_emojiButton__1Obkx {
  font-size: 14px;
  margin: '0 8px 0 0';
}

.comentarioReaccionButton_noselect__3EYVg {
  -webkit-touch-callout: none!important; /* iOS Safari */
    -webkit-user-select: none!important; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none!important; /* Internet Explorer/Edge */
            user-select: none!important; /* Non-prefixed version, currently, supported by Chrome, Edge, Opera and Firefox */
}
.reaccionesPosibles_reaccionEmoji__1co1H {
  cursor: pointer;
  /* margin-right: 4px; */
  font-size: 32px;
  transition: font-size 0.2s ease;
}

.reaccionesPosibles_reaccionEmoji__1co1H:hover {
  font-size: 40px;
}

.reaccionesPosibles_reaccionesContainer__3CC45 {
  display: flex;
  height: 48px;
  line-height: 48px;
  /* width: 450px; */
}

.reaccionesPosibles_reaccionContainer__3yRBv {
  display: inline-block;
  height: 26px;
  line-height: 26px;
}

.reaccionesPosibles_tooltipItem__2bU53 {
  overflow: visible;
  z-index: 9999999;
}
.reactionTabs_reaccionEmoji__17rRg {
    cursor: pointer;
    font-size: 24px;
    transition: all .05s ease-in-out;
    margin: 0 -4px;
}

.reactionTabs_marginHeight__lPHAB {
    min-height: 56px;
    margin: 0 0 32px 0;
}
.reaccionesDadas_reaccionEmoji__ZDmkc {
    cursor: pointer;
    margin-right: 4px;
    font-size: 16px;
    transition: all .05s ease-in-out;
}


/* .reaccionEmoji:hover {
    font-size: 24px;
    transform: scale(1.5);
} */

.reaccionesDadas_reaccionesContainer__2ghdh {
    height: 32px;
    line-height: 32px;
}

.reaccionesDadas_reaccionContainer__1MqTU {
    display: inline-block;
    height: 24px;
    line-height: 24px;
}
#quillEditorContainer {
  height: 90%;
}

#quillEditorContainer .quill .ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #ccc;
}

.ql-container.ql-snow {
  border: none;
}

.ql-toolbar.ql-snow {
  border: none;
  display: flex;
  justify-content: space-evenly;
}

#quillEditorContainer .quill .ql-container.ql-snow {
  height: 90%;
}

#quillEditorContainer .quill .ql-toolbar.ql-snow .ql-formats {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

#quillEditorContainer .quill .ql-container.ql-snow .ql-editor {
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
}

@media (min-width: 768px) {
  #quillEditorContainer .quill .ql-container.ql-snow .ql-editor {
    min-height: 47vh;
    max-height: 47vh;
    height: 47vh;
  }

  #quillEditorContainer .quill .ql-container.ql-snow {
    border: none;
    /* min-height: 364px; */
  }
}

.customToolbar_disabledClear__u628e {
  color: #ed9aa2;
}

.customToolbar_disabledClear__u628e:hover {
  width: 32;
  cursor: not-allowed;
}

.customToolbar_activeClear__2pDer {
  color: #dc3545;
}

.customToolbar_activeClear__2pDer:hover {
  width: 32;
  cursor: pointer;
}

.modalForEditor_modalHeaderContainer__2X2Cj {
  display: flex;
  justify-content: space-between;
  height: 33.33%;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
}

.modalForEditor_modalContentContainer__1PSLj {
  height: 33.33%;
}

.modalForEditor_modalButtonsContainer__XvzXB {
  display: flex;
  justify-content: flex-start;
  height: 33.33%;
}

@media (min-width: 768px) {
  /* .modalPropio {
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 10px;
        width: 200px;
        height: 400px;
      } */

  .modalForEditor_modalContent__uEQhe {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
}

/* El selector #modalForEditorContainer es para que los estilos no se apliquen a TODOS
los modales del proyecto, NO SACARLO !!!! */

@media (max-width: 767px) {
  .modalTarget {
    position: fixed;
    width: 100%;
    margin: 0;
    padding: 0;
    pointer-events: none;
    height: 100%;
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
    /* overflow-y: auto; */
  }

  #modalForEditorContainer.modalTarget .modal-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
    /* padding: ; */
  }

  #modalForEditorContainer.modalTarget .modal-content .modal-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
    /* height: 50px; */
    /* padding: 10px; */
    /* height: 72px; */
  }

  #modalForEditorContainer.modalTarget .modal-content .modal-body {
    position: absolute;
    top: 50px;
    bottom: 60px;
    width: 100%;
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
    /* font-weight: 300; */
    /* overflow: auto; */
  }

  #modalForEditorContainer.modalTarget .modal-content .modal-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
    /* height: 60px;
      padding: 10px; */
  }
}

/* @media (min-width: 768px) {
  .modal-dialog,
  .modal-content {
    80% of window height
    height: 95%;
  }

  .modal-body {
    100% = dialog height, 120px = header + footer
    max-height: 100%;
    overflow-y: scroll;
  }
} */

.userEditor_userEditorContainer__2q6za {
  border-radius: 6px;
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  background-color: #fff;
  color: #7f7f7f;
}

.userEditor_postTextEditor__2XPFH {
  height: 40px;
  line-height: 40px;
  padding: 0 0 0 16px;
  text-align: left;
  background-color: #f2f2f2;
  border-radius: 25px;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.userEditor_commentTextEditor__a8kkk {
  height: 32px;
  line-height: 32px;
  padding: 0 0 0 16px;
  text-align: left;
  background-color: #f2f2f2;
  border-radius: 25px;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.userEditor_commentTextEditor__a8kkk:hover,
.userEditor_postTextEditor__2XPFH:hover {
  background-color: #fafafa;
}

.moreImages_moreImagesContainer__2vsf9 {
    position: absolute;
    height: 125px;
    width: 100%;
    line-height: 125px;
    font-size: 64px;
    color: black;
    background-color: rgba(255, 255, 255, 0.5);
    text-align: center;
    cursor: pointer;
}
.moreImagesVertical_moreImagesContainer__3S0B3 {
    position: absolute;
    height: 250px;
    width: 100%;
    line-height: 250px;
    font-size: 64px;
    color: black;
    background-color: rgba(255, 255, 255, 0.5);
    text-align: center;
    cursor: pointer;
}
.imagesCarousel_centerScreen__2b_r1 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.2);
    height: 500px;
    width: 80%;
}


/* .centerScreen:focus {
    border-width: 4px;
} */

.imagesCarousel_carouselImg__6S6T_ {
    object-fit: scale-down;
    width: 100%;
    height: 500px;
    /* object-position: center; */
    /* overflow: hidden; */
}

@media (min-width: 768px) {
    .imagesCarousel_carouselImg__6S6T_ {
        /* object-fit: cover; */
        /* object-position: center!important; */
        width: 100%;
        height: 100%;
        max-height: 500px;
        /* overflow: hidden; */
    }
}
.reactPlayer_reproductor__1CJGQ {
  border-radius: 6px !important;
  overflow: hidden;
}

.reactPlayer_reproductorRectangular__3VE3A {
  border-radius: 6px !important;
  overflow: hidden;
}

.reaccionesButton_tooltipContainer__1jm83 {
  padding: 8px 16px;
  height: 56px;
  background: black;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 35px;
  box-shadow: 0px 0px 8px 0px rgba(222, 222, 222, 1);
  display: flex;
  align-items: center;
}

.reaccionesButton_reaccionesButtonContainerSinReaccion__jLRgh {
  background-color: #fafafa;
  /* padding: 8px 16px; */
  border-radius: 35px;
  display: flex;
  height: 40px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.reaccionesButton_reaccionesButtonContainerSinReaccion__jLRgh:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}

.reaccionesButton_reaccionesButtonContainerConReaccion__a0pel {
  background-color: #167bff;
  /* padding: 8px 16px; */
  border-radius: 35px;
  display: flex;
  height: 40px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.reaccionesButton_reaccionesButtonContainerConReaccion__a0pel:hover {
  background-color: #3a8fff;
  cursor: pointer;
}

.reaccionesButton_reaccionesButton__YrDx4 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.reaccionesButton_reaccionesButtonContainerConReaccion__a0pel div p,
.reaccionesButton_reaccionesButtonContainerSinReaccion__jLRgh div p {
  margin: 0px 0px 0px 8px;
}

.reaccionesButton_reaccionesButtonContainerConReaccion__a0pel div p {
  color: white;
}

.reaccionesButton_emojiButton__1F_UV {
  font-size: 16px;
}

.reaccionesButton_noselect__2e3cD {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.postInfo_postInfoContainer__yh5_R {
  position: relative;
  margin: 16px 0;
  /* display: block; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* height: 32px;
  line-height: 32px; */
}

.postInfo_postMoreInfo__xybi7 {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
}

.buttonsTab_buttonsContainer__7xm9S {
    margin: 48px 0 0 0;
}

.buttonsTab_particularBtnClass__1ualI {
    border-radius: 35px !important;
    margin: 4px 4px 4px 4px !important;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    padding: 8px 24px 8px 24px !important;
    box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.buttonsTab_active__3SEIl {
    color: #fff;
    background-color: #167bff;
    /* border-color: #167bff; */
    font-weight: bold;
    box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.buttonsTab_notActive__3r_LY {
    color: #212529;
    background-color: #fff;
    border-color: #efefef;
}

.buttonsTab_notActive__3r_LY:hover {
    background-color: #fafafa;
}

.buttonsTab_active__3SEIl:hover {
    color: #fff !important;
}

button.buttonsTab_particularBtnClass__1ualI:focus {
    box-shadow: none;
}
.filtroButton_filtroButton__1kbNK {
    cursor: pointer;
    display: inline-block;
    padding: 8px 16px;
    border-radius: 5px;
    background-color: #167bff;
    color: white;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 13px;
    font-weight: 700;
    vertical-align: middle;
    letter-spacing: normal;
    text-transform: uppercase;
}

.filtroButton_filtroButton__1kbNK:hover {
    background-color: #167bffaa;
}

.dropdownPuroTerapeuta_selectedList__2a1Yy {
    list-style: none;
    padding: 0px;
    text-align: left;
}

.dropdownPuroTerapeuta_selectedItem__2bbAw {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
}

.dropdownPuroTerapeuta_deleteSelectedItem__2Pbjj {
    cursor: pointer;
}

.dropdownPuroTerapeuta_containerButton__3f_wR {
    margin-top: 26px;
}

.dropdownPuroTerapeuta_btnGeneralStyle__1NaQr {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700 !important;
    font-size: 13px !important;
    height: 40px;
    width: 100%;
}

.dropdownPuroTerapeuta_terapeutaName__1muno {
    margin: 0px 0px 0px 16px;
}
.modalEditor_buttonsSameLine__1sDi9 {
    cursor: pointer;
    flex: 0 0 50%;
    max-width: 50%;
}

.modalEditor_selectClass__2cg6I {
    cursor: pointer;
    border-radius: 35px;
    height: 40px;
}


.postMakerFiles_filesContainer__2c40r {
    /* margin: 0px 0px 16px 8px; */
    display: flex;
    align-items: center;
}

.postMakerFiles_labelForFiles__3sqYa {
    margin-bottom: 0px;
}

.postMakerFiles_textFiles__1fVxR {
    color: #167bff;
    margin: 0px 0px 0px 8px;
    font-size: 14px;
}


@media only screen and (max-width: 768px) {
    .postMakerFiles_filesContainer__2c40r {
        margin: 0px 0px 0px 8px;
    }
    .postMakerFiles_buttonsSameLine__1YlKy {
        padding-top: 16px;
    }
}

.boxInfoFeedPaciente_box__2HCG9 {
  margin: 24px 0 24px 0;
  background-color: #ffffff;
  border: 1px;
  border-style: solid;
  border-color: #efefef;
  border-radius: 6px;
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.boxInfoFeedPaciente_contenedorNombreAvatar__1DgTo {
  margin: 16px 24px 16px 24px;
  display: flex;
}

.boxInfoFeedPaciente_contenedorNombre__3IkY8 {
  text-align: left;
  padding: 0px 24px 0px 0px;
  margin: 8px 0px 0px 16px;
}

.boxInfoFeedPaciente_contenedorNombre__3IkY8 p {
  margin-bottom: 0px;
}

.boxInfoFeedPaciente_contenedorAvatar__2uSBv {
  max-width: -webkit-min-content;
  max-width: -moz-min-content;
  max-width: min-content;
  padding: 0px;
}

.boxInfoFeedPaciente_contenedorBotones__KVz1M {
  padding: 16px 0px 16px 0px;
  border-width: 1px;
  border: 1px;
  border-top-style: solid;
  border-color: #efefef;
}

.boxInfoFeedPaciente_linkList__3laEI {
  place-content: center;
  margin-bottom: 0px;
  padding: 0px;
  display: flex;
  list-style: none;
}

.boxInfoFeedPaciente_linkItem__2Ll5k a {
  margin: 0px 8px 0px 8px;
  cursor: pointer;
}

.boxInfoFeedPaciente_linkItem__2Ll5k a:hover {
  color: #167bff;
}

.boxInfoFeedPaciente_itemSelected__3e2Vj {
  color: #167bff !important;
  font-weight: bold;
}

.feedMoreInformation_box__1LHqb {
    margin: 24px 0 24px 0;
    background-color: #ffffff;
    border: 1px;
    border-style: solid;
    border-color: #efefef;
    box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.feedMoreInformation_marginBox__2lDjo {
    margin: 24px 24px 24px 24px;
}

.feedMoreInformation_dataBox__3gfxI {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    padding: 0px 0px 0px 0px !important;
}

.feedMoreInformation_subTitulo__3GsGA {
    text-align: left;
}

.feedMoreInformation_textConcept__2-VFv {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
}

.feedMoreInformation_textValue__24z-U {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
}

.feedMoreInformation_rowStyle__3UiOr {
    margin: 0px 0px 16px 0px;
}

.feedMoreInformation_elementListText__1Rnhx {}

@media only screen and (max-width: 568px) {
    .feedMoreInformation_elementListText__1Rnhx {
        margin-left: 24px;
    }
}
.itemsStyles_textConcept__2_gFE {
  /* padding-left: 0px; */
  padding-right: 0px;
  margin-bottom: 0px;
}

.itemsStyles_textValue__1pnA2 {
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0px;
}

.itemsStyles_rowStyle__1ucx0 {
  margin: 0px 0px 16px 0px;
}

.itemsStyles_dataBox__1dcG5 {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  padding: 0px 0px 0px 0px !important;
}

.boxInfoFeedTerapia_box__14Ra_ {
    margin: 24px 0 24px 0;
    background-color: #ffffff;
    border: 1px;
    border-style: solid;
    border-color: #efefef;
    border-radius: 6px;
    box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.boxInfoFeedTerapia_contenedorNombreAvatar__2UdQO {
    margin: 16px 24px 16px 24px;
    display: flex;
}

.boxInfoFeedTerapia_contenedorNombre__3E3NW {
    text-align: left;
    padding: 0px;
    margin: 8px 0px 0px 16px;
}

.boxInfoFeedTerapia_contenedorNombre__3E3NW p {
    margin-bottom: 0px;
}

.boxInfoFeedTerapia_contenedorAvatar__1ChQb {
    max-width: -webkit-min-content;
    max-width: -moz-min-content;
    max-width: min-content;
    padding: 0px;
}

.boxInfoFeedTerapia_contenedorBotones__188f0 {
    padding: 16px 0px 16px 0px;
    border-width: 1px;
    border: 1px;
    border-top-style: solid;
    border-color: #efefef;
}

.boxInfoFeedTerapia_linkList__Hcjxp {
    place-content: center;
    margin-bottom: 0px;
    padding: 0px;
    display: flex;
    list-style: none;
}

.boxInfoFeedTerapia_linkItem__1M5wi a {
    margin: 0px 8px 0px 8px;
    cursor: pointer;
}

.boxInfoFeedTerapia_linkItem__1M5wi a:hover {
    color: #167bff;
}

.boxInfoFeedTerapia_itemSelected__1YPtX {
    color: #167bff !important;
    font-weight: bold;
}
.creacionTerapias_buttonPosition__Uiyy5 {
  padding: 0 0 0px 0px !important;
}

.creacionTerapias_selectedList__3PisM {
  list-style: none;
  padding: 0px;
  text-align: left;
}

.creacionTerapias_selectedItem__2p4Ga {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.creacionTerapias_deleteSelectedItem__23QUI {
  cursor: pointer;
}

.creacionTerapias_terapeutaName__1wSrf {
  margin: 0px 0px 0px 16px;
}

.creacionTerapias_btnGeneralStyle__pqERp {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700 !important;
  font-size: 13px !important;
  height: 40px;
}

.creacionTerapias_containerButton__2eDIc {
  margin: 24px 0px 24px 0px;
  padding: 0px;
}

.creacionTerapias_containerSelectedList__ZWtIg {
  padding: 0px;
}

@media screen and (max-width: 650px) {
  .creacionPaciente_stepperWeb__3y1cG {
    display: none;
  }
}

@media screen and (min-width: 651px) {
  .creacionPaciente_stepperMobile__DgcUa {
    display: none;
  }
}

.formStepSubtitle_formSubtitle__3BTiU {
  text-align: left;
  margin: 24px 0px 24px 0px;
}

.dropdownTerapeuta_selectedList__1HcRe {
  list-style: none;
  padding: 0px;
  text-align: left;
}

.dropdownTerapeuta_selectedItem__1Jg6u {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.dropdownTerapeuta_deleteSelectedItem__2rjZ9 {
  cursor: pointer;
}

.dropdownTerapeuta_containerButton__FqIsP {
  margin-bottom: 24px;
}

.dropdownTerapeuta_btnGeneralStyle__252VV {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700 !important;
  font-size: 13px !important;
  height: 40px;
}

.dropdownTerapeuta_terapeutaName__2Zlis {
  margin: 0px 0px 0px 16px;
}

.dropdownTerapia_selectedList__39tm- {
  list-style: none;
  padding: 0px;
  text-align: left;
}

.dropdownTerapia_selectedItem__TGvyU {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.dropdownTerapia_deleteSelectedItem__p5rCg {
  cursor: pointer;
}

.dropdownTerapia_btnGeneralStyle__1oiih {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700 !important;
  font-size: 13px !important;
  height: 40px;
}

.dropdownTerapia_containerButton__2WjKT {
  margin-bottom: 24px;
}

.dropdownTerapia_terapeutaName__3dgYf {
  margin: 0px 0px 0px 16px;
}

.stepper_stepperContainer__y1TnN {
    display: flex;
    justify-content: space-between;
}


/* style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}} */

.stepper_stepWrapper__3rDlB {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}


/* style={{backgroundColor: 'blue', borderRadius: '50%', width: '25px', height: '25px', color: 'white'}} */

.stepper_stepNumberWrapperGrey__11Amf {
    font-size: 12px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: white;
    background-color: grey;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.stepper_stepNumberWrapperBlue__1tUU1 {
    font-size: 12px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: white;
    background-color: #167bff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.stepper_dividerLineGrey__11Ym3 {
    overflow: visible;
    height: 2px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 20px;
    width: 100%;
    background-color: #dbdbdb;
}

.stepper_dividerLineBlue__tptde {
    overflow: visible;
    height: 2px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 20px;
    width: 100%;
    background-color: #167bff;
}

.stepper_stepDescriptionGrey__3jaAX {
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
    margin-top: 8px;
    color: #7f7f7f;
}

.stepper_stepDescriptionBlue__2Y0fI {
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
    margin-top: 8px;
    color: #167bff;
}
@media screen and (max-width: 650px) {
  .modificacionPaciente_stepperWeb__1mFdq {
    display: none;
  }
}

@media screen and (min-width: 651px) {
  .modificacionPaciente_stepperMobile__FyD54 {
    display: none;
  }
}

.dashboardAdministradores_customButton__3IR3L {
  border: none;
  background: white;
  margin: 0px 4px 0px 0px;
  padding: 0px;
}
.dashboardAdministradores_customButton__3IR3L:focus {
  outline: none;
}

.dashboardAdministradores_customSvg__3tzSA {
  height: 32px !important;
  width: 32px !important;
}

.dashboardAdministradores_customSvgDisapprove__1y0iu:hover {
  color: #cb2e25 !important;
}

.dashboardAdministradores_customSvgApprove__2TM6b:hover {
  color: #0079e1 !important;
}

.dashboardAdministradores_searchContainer__2cnos {
  margin: 0px 0px 24px 0px;
}

.dashboardAdministradores_buttonEmptyState__2bWyX {
  display: inline-block;
}

.dashboardAdministradores_searchBoxCon__2RlPL {
  padding: 0px 0px 0px 0px;
}

.tableBasicInfo_container__1mGWL > div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  grid-gap: 20px;
  gap: 20px;
}

.tableBasicInfo_container__1mGWL > div > div {
  flex-basis: 50%;
}

.tableBasicInfo_container__1mGWL > div > div h3 {
  font-size: 17px;
}

.tableBasicInfo_container__1mGWL > div > div p {
  font-size: 20px;
}

.dashboardInfoTerapeuta_link_therapy__2hnzp {
  text-decoration: none;
  color: white;
  display: block;
  padding: 5px 10px;
  text-align: center;
  border-radius: 5px;
}

.dashboardInfoTerapeuta_link_therapy__2hnzp:hover {
  opacity: 0.85;
  color: white;
}

.dashboardInfoTerapeuta_link_info__1Bvhk {
  text-decoration: none;
  color: #7f7f7f;
}

.dashboardInfoTerapeuta_link_info__1Bvhk:hover {
  text-decoration: underline;
  color: #0079e1;
}

