.centerScreen {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.2);
    height: 500px;
    width: 80%;
}


/* .centerScreen:focus {
    border-width: 4px;
} */

.carouselImg {
    object-fit: scale-down;
    width: 100%;
    height: 500px;
    /* object-position: center; */
    /* overflow: hidden; */
}

@media (min-width: 768px) {
    .carouselImg {
        /* object-fit: cover; */
        /* object-position: center!important; */
        width: 100%;
        height: 100%;
        max-height: 500px;
        /* overflow: hidden; */
    }
}