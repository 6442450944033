.icon {
    width: 40px;
    height: 40px !important;
    padding: 1px;
    background-color: #ffffff;
    border-radius: 35px;
    border: solid 1px #dfdfdf;
}

.icon:hover {
    border: 1px solid #ff6b6b;
    -webkit-box-shadow: 0px 0px 10px 1px rgba(230, 230, 230, 1);
    -moz-box-shadow: 0px 0px 10px 1px rgba(230, 230, 230, 1);
    box-shadow: 0px 0px 10px 1px rgba(230, 230, 230, 1);
}

.iconImg {
    width: 20px;
    height: 20px;
}