.buttonEmptyState {
  display: inline-block;
}

.searchContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 24px 0px;
}

.cards {
  padding: 8px 8px 8px 8px;
}

.searchBoxCon {
  padding: 0px 0px 0px 0px;
}

.btnCon {
  padding: 0px 0px 0px 0px;
  height: 40px;
}

@media screen and (max-width: 768px) {
  .btnCon {
    margin-top: 16px;
  }
}
