.small {
    margin: 24px !important;
}

.extraSmall {
    margin: 0px 0px 0px 0px !important;
}

.forButtonWhite {
    margin: 0px 8px 0px 0px !important;
    color: white !important;
}

.forButtonBlue {
    margin: 0px 8px 0px 0px !important;
    color: white !important;
}