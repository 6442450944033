.sidebar {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  background: #ffffff;
  border: 1px solid #efefef;
  z-index: 999999;
}

.logoContainer {
  height: 56px;
  margin-bottom: 32px;
  padding-left: 32px;
}

.logo {
  max-width: 100%;
  max-height: 100%;
}

.linkList {
  width: 100%;
  padding: 0px;
  list-style: none;
  text-align: left;
  font: Regular 24px/12px Arial;
  letter-spacing: 0;
  opacity: 1;
}

.linkItem {
  width: 100%;
  height: 48px;
  line-height: 48px;
}

.linkItem a {
  display: block;
  height: 100%;
  width: 100%;
  padding-left: 32px;
}

.linkItem a:hover {
  display: block;
  text-decoration: none;
  color: var(--colorFuentePrincipal);
  background: #fafafa 0% 0% no-repeat padding-box;
  opacity: 0.65;
}

.linkItemSelected,
.linkItemSelected a:hover {
  display: block;
  text-decoration: none;
  color: #167bff !important;
  background: #efefef 0% 0% no-repeat padding-box !important;
  font-weight: bold;
  opacity: 0.65;
  border-left: 2px solid #007bff;
}

@media (min-width: 576px) {
  .sidebarTitle {
    line-height: 56px;
  }
}

@media (min-width: 768px) {
  .linkList {
    margin-top: 0;
  }
  .linkItem a {
    padding-left: 16px;
  }
  .logoContainer {
    padding-left: 16px;
  }
  .sidebar {
    z-index: 100;
  }
}

@media (min-width: 992px) {
  .logoContainer {
    height: 64px;
    margin-bottom: 24px;
    padding-left: 24px;
  }
  .linkItem a {
    padding-left: 24px;
  }
}

@media (min-width: 1200px) {
}
