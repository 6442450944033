.customEditorStyles span {
    font-size: inherit;
    font-family: 'Source Sans Pro', sans-serif;
    color: #7f7f7f;
    line-height: 1.5;
}

.commentStyles span {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #7f7f7f;
    line-height: 1.5;
}

.paginatedText {
    max-height: 128px;
    overflow: hidden;
}
