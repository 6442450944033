.dashboardTerapeuta {
  height: 100%;
}

.customButton {
  border: none;
  background: white;
  margin: 0px 4px 0px 4px;
  padding: 0px;
}
.customButton:focus {
  outline: none;
}

.customSvg {
  height: 32px !important;
  width: 32px !important;
}

.customSvgDelete:hover {
  color: #cb2e25 !important;
}

.customSvgEdit:hover {
  color: #0079e1 !important;
}

.searchContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 24px 0px;
}

.searchBoxCon {
  padding: 0px 0px 0px 0px;
}

.link_info {
  text-decoration: none;
  color: #7f7f7f;
}

.link_info:hover {
  text-decoration: underline;
  color: #0079e1;
}
