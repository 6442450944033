/* El selector #modalForEditorContainer es para que los estilos no se apliquen a TODOS
los modales del proyecto, NO SACARLO !!!! */

@media (max-width: 767px) {
  .modalTarget {
    position: fixed;
    width: 100%;
    margin: 0;
    padding: 0;
    pointer-events: none;
    height: 100%;
    overscroll-behavior: contain;
    /* overflow-y: auto; */
  }

  #modalForEditorContainer.modalTarget .modal-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overscroll-behavior: contain;
    /* padding: ; */
  }

  #modalForEditorContainer.modalTarget .modal-content .modal-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    overscroll-behavior: contain;
    /* height: 50px; */
    /* padding: 10px; */
    /* height: 72px; */
  }

  #modalForEditorContainer.modalTarget .modal-content .modal-body {
    position: absolute;
    top: 50px;
    bottom: 60px;
    width: 100%;
    overscroll-behavior: contain;
    /* font-weight: 300; */
    /* overflow: auto; */
  }

  #modalForEditorContainer.modalTarget .modal-content .modal-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    overscroll-behavior: contain;
    /* height: 60px;
      padding: 10px; */
  }
}

/* @media (min-width: 768px) {
  .modal-dialog,
  .modal-content {
    80% of window height
    height: 95%;
  }

  .modal-body {
    100% = dialog height, 120px = header + footer
    max-height: 100%;
    overflow-y: scroll;
  }
} */
