.listTerapeutas ul {
  display: flex;
  list-style: none;
}

.spanContainer {
  display: flex;
  justify-content: space-around;
  float: right;
  width: 50%;
}

.buttonPosition {
  padding: 0 0 0px 0px !important;
}

.containerSelectedList {
  padding: 0px;
}

.selectedList {
  list-style: none;
  padding: 0px;
  text-align: left;
}

.selectedItem {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.deleteSelectedItem {
  cursor: pointer;
}

.terapeutaName {
  margin: 0px 0px 0px 16px;
}
