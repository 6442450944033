.disabledClear {
  color: #ed9aa2;
}

.disabledClear:hover {
  width: 32;
  cursor: not-allowed;
}

.activeClear {
  color: #dc3545;
}

.activeClear:hover {
  width: 32;
  cursor: pointer;
}
