.box {
  margin: 24px 0 24px 0;
  background-color: #ffffff;
  border: 1px;
  border-style: solid;
  border-color: #efefef;
  border-radius: 6px;
  -webkit-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  -moz-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
  box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.contenedorNombreAvatar {
  margin: 16px 24px 16px 24px;
  display: flex;
}

.contenedorNombre {
  text-align: left;
  padding: 0px 24px 0px 0px;
  margin: 8px 0px 0px 16px;
}

.contenedorNombre p {
  margin-bottom: 0px;
}

.contenedorAvatar {
  max-width: min-content;
  padding: 0px;
}

.contenedorBotones {
  padding: 16px 0px 16px 0px;
  border-width: 1px;
  border: 1px;
  border-top-style: solid;
  border-color: #efefef;
}

.linkList {
  place-content: center;
  margin-bottom: 0px;
  padding: 0px;
  display: flex;
  list-style: none;
}

.linkItem a {
  margin: 0px 8px 0px 8px;
  cursor: pointer;
}

.linkItem a:hover {
  color: #167bff;
}

.itemSelected {
  color: #167bff !important;
  font-weight: bold;
}
