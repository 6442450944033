.userNavbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 4%;
    white-space: nowrap;
    /* margin-right: 72px; */
}

.userNameContainer {
    padding-right: 8px;
    font: Regular 16px/30px Arial;
    letter-spacing: 0;
    opacity: 1;
}

.navbarAvatarContainer {
    height: 24px;
    width: 24px;
    margin-left: 8px;
}

.avatar {
    max-height: 100%;
    max-width: 100%;
    border-radius: 50%;
}

button#dropdown-basic.dropdown-toggle.btn.btn-none {
    padding: 0px;
}

button#dropdown-basic.dropdown-toggle.btn.btn-none::after {
    display: none;
}

button#dropdown-basic.dropdown-toggle.btn.btn-none:focus,
button#dropdown-basic.dropdown-toggle.btn.btn-none:active {
    outline: 0;
    box-shadow: 0 0 0 0 #ffffff;
}

#notificationItem:hover {
    color: #212529AA;
}

#notificationContainer {
    position: relative;
}

#notificationBadge {
    position: absolute;
    top: 0px;
    right: 25%;
}
