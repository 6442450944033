.buttonsContainer {
    margin: 48px 0 0 0;
}

.particularBtnClass {
    border-radius: 35px !important;
    margin: 4px 4px 4px 4px !important;
    min-width: max-content;
    padding: 8px 24px 8px 24px !important;
    -webkit-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
    -moz-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
    box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.active {
    color: #fff;
    background-color: #167bff;
    /* border-color: #167bff; */
    font-weight: bold;
    -webkit-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
    -moz-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
    box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.notActive {
    color: #212529;
    background-color: #fff;
    border-color: #efefef;
}

.notActive:hover {
    background-color: #fafafa;
}

.active:hover {
    color: #fff !important;
}

button.particularBtnClass:focus {
    box-shadow: none;
}