.box {
    margin: 24px 0 24px 0;
    background-color: #ffffff;
    border: 1px;
    border-style: solid;
    border-color: #efefef;
    -webkit-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
    -moz-box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
    box-shadow: 0px 2px 8px 1px rgba(239, 239, 239, 1);
}

.marginBox {
    margin: 24px 24px 24px 24px;
}

.dataBox {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    padding: 0px 0px 0px 0px !important;
}

.subTitulo {
    text-align: left;
}

.textConcept {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
}

.textValue {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
}

.rowStyle {
    margin: 0px 0px 16px 0px;
}

.elementListText {}

@media only screen and (max-width: 568px) {
    .elementListText {
        margin-left: 24px;
    }
}