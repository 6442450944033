.navbarDonadio {
  display: flex;
  justify-content: space-between;
  top: 0px;
  height: 56px;
  width: 100%;
  z-index: 1000;
  background: #ffffff;
  border: 1px solid #efefef;
}

@media (min-width: 768px) {
  .navbarDonadio {
    justify-content: flex-end;
    width: 83.34%;
  }
}

@media (min-width: 992px) {
  .navbarDonadio {
    height: 64px;
  }
}

@media (min-width: 1200px) {
}
